import {
  Baseline,
  BaselineType,
  Network,
  NetworkData,
  NetworkGeometry,
  NetworkType,
  Property,
} from "../Networks"
import axios from "axios"
import { geoJSONProject } from "@edugis/proj-convert"
import { Feature, FeatureCollection, LineString, Point } from "geojson"
import { calculateSpeed, calculateTime } from "../../utils/mapbox/FeaturesUtils"
import { coordEach } from "@turf/turf"
import { getProject } from "../../services/project.service"
import { decompressSync, strFromU8, strToU8 } from "fflate"

const FREQUENCY = {
  bus: 10,
  train: 30,
  tram: 10,
}

const SPEED = {
  bus: 25,
  train: 35,
  tram: 25,
}

const condition = [
  {
    label: "Above ground",
    value: "above ground",
    localeKey: "condition.above_ground",
  },
  {
    label: "Bridge",
    value: "bridge",
    localeKey: "condition.bridge",
  },
  {
    label: "Tunnel",
    value: "tunnel",
    localeKey: "condition.tunnel",
  },
  {
    label: "Underground",
    value: "underground",
    localeKey: "condition.underground",
  },
]

const park_and_ride = [
  {
    label: "Garage",
    value: "garage",
    localeKey: "par.garage",
  },
  {
    label: "Open-air",
    value: "open-air",
    localeKey: "par.open_air",
  },
  { label: "None", value: "None", localeKey: "par.none" },
]

const setDefaultValue = function (
  geometry: LineString | Point,
  propertyName: string,
  networkType: NetworkType
): string | number | boolean {
  const baseline: Baseline =
    geometry.type === "LineString"
      ? networkType.baselineProperties.Network!
      : networkType.baselineProperties.Stops!
  switch (propertyName) {
    case "uid":
      return baseline.nextMaxId
    case "condition":
      return "above ground"
    case "frequency_min":
      return FREQUENCY[networkType.name]
    case "speed":
      return SPEED[networkType.name]
    case "multimodal_hub":
      return false
    case "mode":
      return networkType.name
    case "category":
      if (networkType.name !== "bike") {
        return `transit_${geometry.type === "Point" ? "stop" : "line"}`
      } else {
        return "bff"
      }
    case "status":
      return "added"
    case "park_and_ride":
      return "None"
    case "type":
      return `${networkType.name}_${
        geometry.type === "Point" ? "stop" : "line"
      }`
    case "line":
      return `${baseline.nextLineId}`
    case "time_intersection_sec":
      if (networkType.name == "bike") {
        return 2
      } else {
        return 0
      }
    case "congestion_zone":
      return 2
    case "location":
      return "medium_density"
  }
  return ""
}

const networkDefaults: Property[] = [
  {
    propertyName: "uid",
    level: "segment",
    localeKey: "id",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "mode",
    level: "segment",
    localeKey: "unknown",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "name",
    level: "route",
    localeKey: "name",
    datatype: "string",
  },
  {
    propertyName: "category",
    level: "segment",
    localeKey: "unknown",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "status",
    level: "segment",
    localeKey: "unknown",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "line",
    level: "segment",
    localeKey: "line",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "speed",
    level: "segment",
    localeKey: "speed_kmph",
    datatype: "number",
    precision: 1,
    bulk: true,
    onChange: calculateTime,
  },
  {
    propertyName: "time_min",
    level: "segment",
    localeKey: "time_min",
    datatype: "number",
    precision: 1,
    onChange: calculateSpeed,
  },
  {
    propertyName: "frequency_min",
    level: "route",
    localeKey: "frequency",
    datatype: "number",
    precision: 1,
    bulk: true,
    onChange: (geometry, properties) =>
      (properties["freq_morning"] = properties["frequency_min"]),
  },
  {
    propertyName: "condition",
    level: "segment",
    localeKey: "condition",
    datatype: "dropdown",
    options: condition,
  },
]

const stopDefaults: Property[] = [
  {
    propertyName: "uid",
    level: "segment",
    localeKey: "id",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "mode",
    level: "segment",
    localeKey: "unknown",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "category",
    level: "segment",
    localeKey: "unknown",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "status",
    level: "segment",
    localeKey: "unknown",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "type",
    level: "segment",
    localeKey: "unknown",
    datatype: "string",
    disabled: true,
  },
  {
    propertyName: "stop_name",
    level: "segment",
    localeKey: "name",
    datatype: "string",
  },
  {
    propertyName: "park_and_ride",
    level: "segment",
    localeKey: "park_and_ride",
    datatype: "dropdown",
    options: park_and_ride,
  },
  {
    propertyName: "multimodal_hub",
    level: "segment",
    localeKey: "multimodal_hub",
    datatype: "boolean",
    bulk: true,
  },
  {
    propertyName: "condition",
    level: "segment",
    localeKey: "condition",
    datatype: "dropdown",
    options: condition,
    bulk: true,
    disabled: true,
  },
  {
    propertyName: "congestion_zone",
    level: "segment",
    localeKey: "congestion_zone",
    datatype: "number",
    disabled: true,
  },
  {
    propertyName: "location",
    level: "segment",
    localeKey: "location",
    datatype: "string",
    disabled: true,
  },
]

function prepareProperties(
  defaultProperties: Property[],
  extraProperties: Property[]
) {
  const properties = defaultProperties.concat(extraProperties)
  return properties.map((property) => {
    property.defaultValue = setDefaultValue
    return property
  })
}

export default class Antwerp implements Network {
  name: string = "antwerp-v5"
  sourceCRSName: string = ""
  originalCRS: string = ""

  loadNetworkData(): NetworkData[] {
    const networkData: NetworkData[] = []
    this.getData().then((value) => {
      let features: Feature[] = []
      value?.forEach((fc) => (features = features.concat(fc.features)))
      const modeGrouped = features.reduce((cb, feature) => {
        const baselineType: BaselineType =
          feature.geometry.type === "Point" ? "Stops" : "Network"
        if (feature.properties) {
          cb[feature.properties["mode"]] = cb[feature.properties["mode"]] || {}
          cb[feature.properties["mode"]][baselineType] =
            cb[feature.properties["mode"]][baselineType] || []
          cb[feature.properties["mode"]][baselineType].push(feature)
          return cb
        }
      }, Object.create(null))
      this.networkTypes.forEach((networkType) => {
        networkType.baselines.forEach((baseline) => {
          const features = modeGrouped[networkType.name][baseline]
          features.forEach((feature: NetworkGeometry) => {
            feature.id = networkType.getPropertyFromFeature(feature, "id")
          })

          const featureCollection: FeatureCollection = {
            type: "FeatureCollection",
            features: features,
          }

          coordEach(featureCollection, function (currentCoord) {
            if (currentCoord.length === 3) {
              currentCoord.splice(2, 1)
            }
          })

          const updateBaseline = networkType.baselineProperties[baseline]
          const ids = features.map((f: NetworkGeometry) =>
            parseInt(f.id!.toString().split("-")[2])
          )
          const maxId = this.getMax(ids)
          let lineId: number = 0
          if (networkType.name != "bike" && baseline == "Network") {
            const ids = features.map((f: NetworkGeometry) =>
              parseInt(f.properties["line"])
            )
            lineId = this.getMax(ids)
            if (networkType.baselineProperties && updateBaseline) {
              updateBaseline["lineId"] = lineId
            }
          }
          if (networkType.baselineProperties && updateBaseline) {
            updateBaseline["maxId"] = maxId
          }

          networkData.push({
            networkType: networkType,
            baseline: networkType.baselineProperties[baseline]!,
            baselineType: baseline,
            data: featureCollection,
          })
        })
      })
    })
    return networkData
  }

  getMax(arr) {
    return arr.reduce((max, v) => (max >= v ? max : v), -Infinity)
  }

  updateOriginalData(updatedFeatures, originalData) {
    const originalDataDict = originalData.reduce((acc, item, index) => {
      acc[item.name] = { item, index }
      return acc
    }, {})

    Object.keys(updatedFeatures).forEach((key) => {
      const updatedData = updatedFeatures[key]

      if (updatedData.features.length > 0) {
        const originalDataEntry = originalDataDict[key]
        if (originalDataEntry) {
          const updatedFeaturesArray = updatedData.features
          const originalFeaturesArray = originalDataEntry.item.features

          updatedFeaturesArray.forEach((updatedFeature) => {
            if (updatedFeature.properties.status === "added") {
              originalFeaturesArray.push(updatedFeature)
            } else if (updatedFeature.properties.status === "updated") {
              const idx = originalFeaturesArray.findIndex(
                (d) => d.properties.uid === updatedFeature.properties.uid
              )
              if (idx > -1) {
                originalFeaturesArray[idx] = updatedFeature
              }
            } else if (updatedFeature.properties.status === "deleted") {
              const idx = originalFeaturesArray.findIndex(
                (d) => d.properties.uid === updatedFeature.properties.uid
              )
              if (idx > -1) {
                originalFeaturesArray.splice(idx, 1)
              }
            }
          })

          originalData[originalDataEntry.index] = {
            ...originalDataEntry.item,
            features: originalFeaturesArray,
          }
        }
      }
    })
    return originalData
  }

  async getData() {
    const urlParam = new URLSearchParams(window.location.search)
    const projectId = urlParam.get("projectId")

    if (projectId) {
      const arr: FeatureCollection[] = []
      try {
        await axios.get(`api/network?name=${this.name}`).then((result) => {
          const data = result.data
          this.sourceCRSName = data.sourceCRS
          const baselines = data.baseline
          baselines.forEach((baseline) => {
            baseline.geojson = JSON.parse(
              this.decompressAndDecodeBase64(baseline.geojson)
            )
            this.originalCRS = baseline.geojson.crs
            arr.push(geoJSONProject(baseline.geojson))
          })
        })
        const res = await getProject(projectId)
        const data = res.data.geojson
        Object.keys(data).forEach((key) => {
          const geojson = data[key]
          geojson.crs = {
            type: "name",
            properties: {
              name: "EPSG:31370",
            },
          }
          data[key] = geoJSONProject(geojson)
        })
        const updateOriginalData = this.updateOriginalData(data, arr)
        return updateOriginalData
      } catch (err) {
        console.error("Error:", err)
      }
    } else {
      const arr: FeatureCollection[] = []
      await axios.get(`api/network?name=${this.name}`).then((result) => {
        const data = result.data
        this.sourceCRSName = data.sourceCRS
        const baselines = data.baseline
        baselines.forEach((baseline) => {
          baseline.geojson = JSON.parse(
            this.decompressAndDecodeBase64(baseline.geojson)
          )
          this.originalCRS = baseline.geojson.crs
          arr.push(geoJSONProject(baseline.geojson))
        })
      })
      return arr
    }
  }

  decompressAndDecodeBase64(data) {
    const compressedString = atob(data)
    const compressedU8Buffer = strToU8(compressedString, true)
    const decompressed = decompressSync(compressedU8Buffer)
    return strFromU8(decompressed)
  }

  networkTypes: NetworkType[] = [
    NetworkType.BIKE.init({
      Network: new Baseline(
        "uid",
        "category",
        "line",
        "EDG-BKE",
        prepareProperties(
          [],
          [
            {
              propertyName: "uid",
              level: "segment",
              localeKey: "id",
              datatype: "string",
              disabled: true,
            },
            {
              propertyName: "mode",
              level: "segment",
              localeKey: "unknown",
              datatype: "string",
              disabled: true,
            },
            {
              propertyName: "category",
              level: "segment",
              localeKey: "unknown",
              datatype: "string",
              disabled: true,
            },
            {
              propertyName: "has_improvement",
              level: "segment",
              localeKey: "unknown",
              datatype: "boolean",
              disabled: true,
            },
            {
              propertyName: "status",
              level: "segment",
              localeKey: "unknown",
              datatype: "string",
              disabled: true,
            },
            {
              propertyName: "is_baseline",
              level: "segment",
              localeKey: "unknown",
              datatype: "boolean",
              disabled: true,
            },
            {
              propertyName: "time_min",
              level: "segment",
              localeKey: "time_min",
              datatype: "number",
              precision: 1,
              onChange: calculateSpeed,
              disabled: true,
            },
            {
              propertyName: "speed_kmph",
              level: "segment",
              localeKey: "speed_kmph",
              datatype: "number",
              precision: 2,
              bulk: true,
              onChange: calculateTime,
              disabled: true,
            },
            {
              propertyName: "category",
              level: "segment",
              localeKey: "category",
              datatype: "dropdown",
              bulk: true,
              options: [
                { label: "bff", value: "bff", localeKey: "category.bff" },
                {
                  label: "fietssnelweg",
                  value: "fietssnelweg",
                  localeKey: "category.cycle_highway",
                },
                {
                  label: "bike",
                  value: "bike",
                  localeKey: "category.other_mixed",
                },
                {
                  label: "urban bike",
                  value: "urban bike",
                  localeKey: "category.bike_path_urban",
                },

                {
                  label: "dense urban bike",
                  value: "dense urban bike",
                  localeKey: "category.bike_path_dense_urban",
                },
                {
                  label: "city bike",
                  value: "city bike",
                  localeKey: "category.bike_path_city",
                },
                {
                  label: "ringfietspad",
                  value: "ringfietspad",
                  localeKey: "category.ringfietspad",
                },
                {
                  label: "ferries",
                  value: "ferries",
                  localeKey: "category.ferries",
                },
                {
                  label: "bike tunnels",
                  value: "bike tunnels",
                  localeKey: "category.bike_tunnels",
                },
              ],
            },
            {
              propertyName: "time_intersection_sec",
              level: "segment",
              localeKey: "time_intersection_sec",
              datatype: "number",
              disabled: true,
            },
            {
              propertyName: "condition",
              level: "segment",
              localeKey: "condition",
              datatype: "dropdown",
              options: condition,
              disabled: true,
            },
          ]
        )
      ),
    }),
    NetworkType.BUS.init({
      Network: new Baseline(
        "uid",
        "name",
        "line",
        "EDG-BUS",
        prepareProperties(networkDefaults, [])
      ),
      Stops: new Baseline(
        "uid",
        "stop_name",
        "line",
        "STP-BUS",
        prepareProperties(stopDefaults, [])
      ),
    }),
    NetworkType.TRAIN.init({
      Network: new Baseline(
        "uid",
        "name",
        "line",
        "EDG-TRN",
        prepareProperties(networkDefaults, [])
      ),
      Stops: new Baseline(
        "uid",
        "stop_name",
        "line",
        "STP-TRN",
        prepareProperties(stopDefaults, [])
      ),
    }),
    NetworkType.TRAM.init({
      Network: new Baseline(
        "uid",
        "name",
        "line",
        "EDG-TRM",
        prepareProperties(networkDefaults, [])
      ),
      Stops: new Baseline(
        "uid",
        "stop_name",
        "line",
        "STP-TRM",
        prepareProperties(stopDefaults, [])
      ),
    }),
  ]
}
