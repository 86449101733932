import request from "./api"

export function login(username: string, password: string) {
  return request.post("/login", { username, password })
}

export function validateSignUpToken(inviteToken: { inviteToken: string }) {
  return request.post("/invite/validate-token", inviteToken)
}

export function createUserAndUpdateInviteStatus(userSignUp: UserSignup) {
  return request.post("/user", userSignUp)
}

export function getUsers() {
  return request.get("/users/all", {
    headers: {
      "x-show-loader": true,
    },
  })
}

export function getInvites() {
  return request.get("/invite/", {
    headers: {
      "x-show-loader": true,
    },
  })
}

export function generateInviteToken(userInvite: UserInvite) {
  return request.post("/invite/generate", userInvite)
}

export function deleteUser(userId: string) {
  return request.delete("/user/" + userId)
}

export function updateUserById(user: User) {
  return request.put("/user/" + user.id, user)
}

export function updateUserPasswordById(data: {
  id: string
  oldHash: string
  newHash: string
}) {
  return request.put("/user/change-password", data)
}

export function cancelUserInviteByToken(data) {
  return request.put("/invite/cancel", data)
}

export function getUserById(userId: string) {
  return request.get("/user/" + userId)
}

export function getAllUserProjects(userId: string) {
  return request.get("/user/" + userId + "/projects", {
    headers: {
      "x-show-loader": true,
    },
  })
}
