import { CircleLayerSpecification, LineLayerSpecification } from "mapbox-gl"
import { Feature, FeatureCollection, LineString, Point } from "geojson"
import intl from "react-intl-universal"
import Color from "color"

type NetworkTypeName = "bike" | "bus" | "train" | "tram" | "water"
type BaselineType = "Network" | "Stops"
type BaselineProperties = { [key in BaselineType]?: Baseline }
type NetworkGeometry = Feature<LineString | Point, GeoJsonProperties>
type PropertyLevel = "route" | "segment" | "all"
type PropertyDataType = "string" | "number" | "boolean" | "dropdown"
type GeoJsonProperties = { [key: string]: any }

interface Network {
  name: string
  networkTypes: NetworkType[]
  sourceCRSName: string
  originalCRS: any
  loadNetworkData: () => NetworkData[]
}

class Baseline {
  private readonly idField: string
  private readonly labelField: string
  private readonly routeIdField: string
  private readonly propertiesArr: Property[]
  private readonly idPrefix: string
  private maxId: number | undefined
  private lineId: number | undefined

  constructor(
    idField: string,
    labelField: string,
    routeIdField: string,
    idPrefix: string,
    properties: Property[]
  ) {
    this.idField = idField
    this.labelField = labelField
    this.routeIdField = routeIdField
    this.idPrefix = idPrefix
    this.propertiesArr = properties
  }

  getPropertyFor(identifier: string) {
    return this.properties.find((d) => d.propertyName == identifier)
  }

  get routeId() {
    return this.routeIdField
  }

  get label() {
    return this.labelField
  }

  get id() {
    return this.idField
  }

  get properties() {
    return this.propertiesArr
  }

  get nextMaxId() {
    this.maxId = (this.currentMaxId || 0) + 1
    return this.idPrefix + "-" + this.maxId
  }

  get currentMaxId() {
    return this.maxId
  }

  get nextLineId() {
    this.lineId = (this.lineId || 0) + 1
    return this.lineId
  }
}

interface Property {
  propertyName: string
  localeKey: string
  level: PropertyLevel
  datatype: PropertyDataType
  precision?: number
  options?: { label: string; value: string; localeKey: string }[]
  readonly?: boolean
  bulk?: boolean
  onChange?: (
    geometry: LineString | Point,
    properties: GeoJsonProperties
  ) => any
  defaultValue?: (
    geometry: LineString | Point,
    propertyName: string,
    networkType: NetworkType
  ) => string | number | boolean | undefined
  disabled?: boolean
}

class NetworkType {
  static BIKE: NetworkType = new NetworkType(
    "bike",
    "#961414",
    ["Network"],
    false,
    "fa-bicycle"
  )
  static BUS: NetworkType = new NetworkType(
    "bus",
    "#41964B",
    ["Network", "Stops"],
    true,
    "fa-bus"
  )
  static TRAIN: NetworkType = new NetworkType(
    "train",
    "#D7A023",
    ["Network", "Stops"],
    true,
    "fa-train"
  )
  static TRAM: NetworkType = new NetworkType(
    "tram",
    "#8738D2",
    ["Network", "Stops"],
    true,
    "fa-train-tram"
  )
  static WATER: NetworkType = new NetworkType("water", "#61A7FF", [
    "Network",
    "Stops",
  ])

  name: NetworkTypeName
  color: string
  baselineProperties: BaselineProperties
  baselines: BaselineType[]
  visible: boolean | undefined
  networkTypeIcon: string | undefined

  constructor(
    name: NetworkTypeName,
    color: string,
    baselines: BaselineType[],
    visible?: boolean,
    networkTypeIcon?: string
  ) {
    this.name = name
    this.color = color
    this.baselineProperties = {}
    this.baselines = baselines
    this.visible = visible
    this.networkTypeIcon = networkTypeIcon
  }

  public init(baselineProperties: BaselineProperties) {
    this.baselineProperties = baselineProperties
    return this
  }

  public getPropertyFromFeature(
    feature: NetworkGeometry,
    property: "id" | "label" | "routeId"
  ) {
    if (feature.geometry == undefined || feature.geometry.type == undefined) {
      return ""
    }
    const baseline: Baseline =
      feature.geometry.type === "Point"
        ? this.baselineProperties.Stops!
        : this.baselineProperties.Network!
    let field = ""
    switch (property) {
      case "id":
        field = baseline.id
        break
      case "label":
        field = baseline.label
        break
      case "routeId":
        field = baseline.routeId
        break
    }
    return this.parsePropertyValue(
      field,
      baseline.getPropertyFor(field),
      feature.properties,
      baseline
    )
  }

  private parsePropertyValue(
    field: string,
    property: Property | undefined,
    properties: GeoJsonProperties,
    baseline: Baseline
  ) {
    if (property && properties) {
      let val = properties[field]
      if (property.datatype === "number" && val.length > 0) {
        return parseFloat(val)
      } else if (property.datatype === "boolean") {
        return val === 1 || val === "1"
      } else if (
        property.propertyName == "category" &&
        properties["mode"] === "bike"
      ) {
        baseline.properties.forEach((property) => {
          if (property.propertyName === "category") {
            property.options?.forEach((option) => {
              if (option.label === val) {
                val = intl.get(option.localeKey)
              }
            })
          }
        })
        return val
      }
      return val
    }
    return ""
  }
}

interface NetworkData {
  networkType: NetworkType
  baseline: Baseline
  baselineType: BaselineType
  data: FeatureCollection
}

function getLinePaintStyle(
  networkType: NetworkType,
  lightTheme: boolean = false
): LineLayerSpecification["paint"] {
  let lineWidthExpression

  if (networkType.name === "bike") {
    lineWidthExpression = [
      "match",
      ["get", "category"],
      "bike",
      1,
      "urban bike",
      1,
      "dense urban bike",
      1,
      "bff",
      2,
      "city bike",
      1,
      "ringfietspad",
      3,
      "ferries",
      1,
      "bike tunnels",
      1,
      "fietssnelweg",
      3,
      3,
    ]
  } else if (networkType.name === "bus") {
    lineWidthExpression = 4
  } else {
    lineWidthExpression = 5
  }
  let lineColor
  if (lightTheme) {
    lineColor = [
      "case",
      ["!=", ["get", "status"], "baseline"],
      networkType.color,
      Color(networkType.color).lighten(0.6).hex(), // Default color
    ]
  } else {
    lineColor = [
      "case",
      [
        "all",
        ["==", ["get", "mode"], "bike"],
        // ["==", ["get", "has_improvement"], true],
        ["!=", ["get", "status"], "baseline"],
      ],
      Color(networkType.color).alpha(0.5).lighten(0.9).hex(),
      // ["==", ["get", "has_improvement"], true],
      ["!=", ["get", "status"], "baseline"],
      Color(networkType.color).alpha(0.5).lighten(0.6).hex(),
      networkType.color, // Default color
    ]
  }

  return {
    "line-color": lineColor,
    "line-width": lineWidthExpression,
    "line-opacity": 1,
  }
}

function getStopsPaintStyle(
  networkType: NetworkType,
  lightTheme: boolean = false
): CircleLayerSpecification["paint"] {
  let circleColor
  if (lightTheme) {
    circleColor = [
      "case",
      ["!=", ["get", "status"], "baseline"],
      networkType.color,
      Color(networkType.color).lighten(0.8).hex(),
    ]
  } else {
    circleColor = [
      "case",
      [
        "all",
        ["==", ["get", "mode"], "bike"],
        // ["==", ["get", "has_improvement"], true],
        ["!=", ["get", "status"], "baseline"],
      ],
      Color(networkType.color).alpha(0.5).lighten(0.9).hex(),
      // ["==", ["get", "has_improvement"], true],
      ["!=", ["get", "status"], "baseline"],
      Color(networkType.color).alpha(0.5).lighten(0.5).hex(),
      networkType.color, // Default color
    ]
  }

  let circleRadius = 4
  if (networkType.name === "bus") {
    circleRadius = 3
  }
  if (lightTheme) {
    circleRadius = 2
  }

  return {
    "circle-color": "#303030",
    "circle-radius": circleRadius,
    "circle-stroke-color": circleColor,
    "circle-stroke-width": 2,
  }
}

function getStyleForLayer(
  layerId: string,
  networkType: NetworkType,
  baselineType: BaselineType,
  sourceId: string,
  lightTheme: boolean
): LineLayerSpecification | CircleLayerSpecification {
  switch (baselineType) {
    case "Network":
      return {
        id: layerId,
        type: "line",
        paint: getLinePaintStyle(networkType, lightTheme),
        source: "",
        layout: {
          "line-join": "round",
          "line-cap": "round",
          visibility: "visible",
          "line-sort-key": [
            "case",
            ["==", ["get", "status"], "baseline"],
            1,
            2,
          ],
        },
      }
    case "Stops":
      return {
        id: layerId,
        type: "circle",
        paint: getStopsPaintStyle(networkType, lightTheme),
        source: "",
        layout: {
          visibility: "visible",
        },
      }
  }
}

export { NetworkType, getStyleForLayer, Baseline }
export type {
  BaselineType,
  Network,
  NetworkData,
  NetworkGeometry,
  Property,
  PropertyLevel,
  GeoJsonProperties,
  NetworkTypeName,
}
