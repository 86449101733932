import request from "./api"

export function getConfigurations() {
  return request.get("/configuration", {
    headers: {
      "x-show-loader": true,
    },
  })
}

export function updateConfigurations(configuration: Configuration) {
  return request.put("/configuration", configuration)
}
