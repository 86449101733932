import MapboxDraw, {
  DrawCustomMode,
  DrawFeature,
  MapMouseEvent,
} from "@mapbox/mapbox-gl-draw"
import { NetworkGeometry } from "../../specs/Networks"
import { GeoJSON } from "geojson"
import {
  EditModeOptions,
  EditModeState,
  MapEvents,
  SelectStack,
} from "../../specs/Mapbox"
import {
  isDelete,
  isEsc,
  isRedo,
  isUndo,
  redo,
  undo,
} from "../../utils/mapbox/Commons"
import {
  clickActiveFeature,
  clickInactive,
  clickNoTarget,
  clickVertex,
  dragVertex,
  editModeDisplayFeatures,
  handleDraw,
  higlightFeaturesOnHover,
  insideMouseMove,
  isNonRouteFeature,
  moveOverlappingFeatures,
  popup,
  showRouteSelector,
  startDragging,
} from "./CommonOperationMode"

const { doubleClickZoom } = MapboxDraw.lib
const CommonSelectors = MapboxDraw.lib.CommonSelectors
const { isActiveFeature, isInactiveFeature, isVertex, noTarget } =
  CommonSelectors
let draw: MapboxDraw
const EditMode: DrawCustomMode<EditModeState, EditModeOptions> = {
  onSetup(options: EditModeOptions): EditModeState {
    const featureId = options.featureId
    let feature: DrawFeature | undefined = undefined
    if (featureId) {
      feature = this.getFeature(featureId)
    }

    const state: EditModeState = {
      feature: feature,
      dragMoving: false,
      canDragMove: false,
      actionMode: options.actionMode,
      isRouteSelected: false,
      selectedCoordPaths: [],
      selectedNetworkType: options.selectedNetworkType,
      featureOrg: {},
      featuresAtPoint: [],
      shiftPressed: false,
      selectedFeatures: [],
      routeFeatureIds: [],
    }

    if (featureId) {
      this.setSelected(featureId)
      state.isRouteSelected = true
      if (feature) {
        state.featureOrg[featureId] = feature.toGeoJSON() as NetworkGeometry
        const stackItem: SelectStack = {
          mode: "edit_mode",
          operation: "selection_change",
          features: [feature.toGeoJSON() as NetworkGeometry],
          properties: {},
        }
        this.map.fire(MapEvents.SELECTION_CHANGE, {
          stackItem,
        })
      }
    }
    doubleClickZoom.disable(this)

    if (state.actionMode == "add_mode" && options.event) {
      clickInactive.call(this, state, options.event as any, draw, false)
    }

    draw = this["_ctx"]["api"]
    return state
  },

  // MapboxGL Draw
  onClick(state: EditModeState, e: any) {
    if (isNonRouteFeature(state, e)) return

    const isShiftClick = CommonSelectors.isShiftDown(e)

    if (noTarget(e)) {
      // Reset selection
      clickNoTarget.call(this, state, e, isShiftClick)
    } else if (isActiveFeature(e)) {
      // if feature is selected, Add stop or control point
      clickActiveFeature.call(this, e, isShiftClick, state, draw)
    } else if (isInactiveFeature(e)) {
      // if feature is not selected, then select feature
      clickInactive.call(this, state, e, draw, isShiftClick)
    } else if (isVertex(e)) {
      // vertex clicked
      clickVertex.call(this, e, isShiftClick, state, draw)
    }

    const stackItem: SelectStack = {
      mode: "edit_mode",
      operation: "selection_change",
      features: draw.getSelected().features as NetworkGeometry[],
      properties: {},
    }

    if (state.actionMode === "edit_mode") {
      this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
    }
  },

  onMouseMove(state: EditModeState, e: MapboxDraw.MapMouseEvent) {
    if (isNonRouteFeature(state, e)) return

    if (state.dragMoving) return

    if (state.isRouteSelected) {
      // highlights feature and sets features at point
      higlightFeaturesOnHover.call(this, state, e, draw)
    } else if (state.selectedNetworkType.name === "bike") {
    } else {
      showRouteSelector.call(this, state, e, draw)
    }

    // On mousemove that is not a drag, stop vertex movement.
    insideMouseMove.call(this, state, e, draw)
  },

  // MapboxGL Draw
  onDrag(state: EditModeState, e: MapMouseEvent) {
    if (isNonRouteFeature(state, e)) return

    popup.remove()
    if (!state.canDragMove) return

    state.dragMoving = true
    e.originalEvent.stopPropagation()

    const delta = {
      lng: e.lngLat.lng - state.dragMoveLocation!.lng,
      lat: e.lngLat.lat - state.dragMoveLocation!.lat,
    }
    if (state.selectedCoordPaths.length > 0) {
      dragVertex.call(this, state, e, delta)
    } else {
      state.dragMoveLocation = e.lngLat
      moveOverlappingFeatures.call(this, state, draw)
    }
  },

  onMouseUp(state: EditModeState, e: MapboxDraw.MapMouseEvent) {
    if (isNonRouteFeature(state, e)) return

    handleDraw.call(this, state, draw)
  },

  onMouseDown(state: EditModeState, e: MapboxDraw.MapMouseEvent) {
    if (isVertex(e) && e.featureTarget.properties) {
      state.selectedCoordPaths = [
        {
          feature_id: e.featureTarget.properties.parent,
          coord_path: e.featureTarget.properties.coord_path,
        },
      ]
      startDragging.call(this, state, e)
      state.featureOrg[e.featureTarget.properties.parent] = this.getFeature(
        e.featureTarget.properties.parent
      ).toGeoJSON() as NetworkGeometry
    } else {
      state.selectedCoordPaths = []
      state.featuresAtPoint.forEach((f) => {
        state.featureOrg[f.feature.id!.toString()] = f.feature
      })
    }
  },

  onTouchEnd(state: EditModeState, e: any) {
    this.onMouseUp!(state, e)
  },

  onStop(_: EditModeState) {
    doubleClickZoom.enable(this)
    this.clearSelectedCoordinates()
  },

  onTouchStart(state: EditModeState, e: any) {
    this.onMouseDown!(state, e)
  },

  toDisplayFeatures(
    state: EditModeState,
    geojson: any,
    display: (geojson: GeoJSON) => void
  ) {
    editModeDisplayFeatures(state, geojson, display)
  },

  onKeyUp(state: EditModeState, e: any) {
    if (isUndo(e)) {
      undo()
    } else if (isRedo(e)) {
      redo()
    } else if (isDelete(e)) {
      if (this.getSelected().length > 0) {
        draw.delete(this.getSelectedIds())
      }
    } else if (isEsc(e)) {
      state.isRouteSelected = false
      this.clearSelectedFeatures()
      if (state.actionMode !== "add_mode") {
        draw.getAll().features.forEach((element) => {
          draw.delete(element.id!.toString())
        })
        this.map.fire(MapEvents.ROUTE_DESELECTED, {})
      } else {
        const stackItem: SelectStack = {
          mode: "add_mode",
          operation: "selection_change",
          features: [],
          properties: {},
        }

        this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
      }
    }
  },
}

export default EditMode
