import { Template } from "@pdfme/common"

export const template: Template = {
  schemas: [
    [
      {
        name: "projectName",
        type: "text",
        content: "Project Name",
        position: {
          x: 15,
          y: 18,
        },
        width: 180,
        height: 10,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "top",
        fontSize: 18,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Bold",
      },
      {
        name: "simulationConfig",
        type: "text",
        content: "Configurations of the simulation",
        position: {
          x: 15,
          y: 35,
        },
        width: 180,
        height: 8,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "bottom",
        fontSize: 12,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Bold",
      },
      {
        name: "tripsSampledValue",
        type: "text",
        content: "000000",
        position: {
          x: 179,
          y: 43,
        },
        width: 16,
        height: 8,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "top",
        fontSize: 12,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Regular",
      },
      {
        name: "simulationResult",
        type: "text",
        content: "Results of the simulation",
        position: {
          x: 15,
          y: 55,
        },
        width: 180,
        height: 8,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "bottom",
        fontSize: 12,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Bold",
      },
      {
        name: "distanceNumValue",
        type: "text",
        content: "000000*",
        position: {
          x: 179,
          y: 63,
        },
        width: 26,
        height: 8,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "top",
        fontSize: 12,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Regular",
      },
      {
        name: "tripsNumValue",
        type: "text",
        content: "000000*",
        position: {
          x: 179,
          y: 68,
        },
        width: 26,
        height: 8,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "top",
        fontSize: 12,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Regular",
      },
      {
        name: "tripsSampled",
        type: "text",
        content: "Number of trips sampled",
        position: {
          x: 20,
          y: 43,
        },
        width: 158,
        height: 8,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "top",
        fontSize: 12,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Regular",
      },
      {
        name: "distanceNum",
        type: "text",
        content: "Modal Shift in converted car trips",
        position: {
          x: 20,
          y: 63,
        },
        width: 152,
        height: 8,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "top",
        fontSize: 12,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Regular",
      },
      {
        name: "tripsNum",
        type: "text",
        content: "Modal Shift in converted car kilometers",
        position: {
          x: 20,
          y: 68,
        },
        width: 152,
        height: 8,
        rotate: 0,
        alignment: "left",
        verticalAlignment: "top",
        fontSize: 12,
        lineHeight: 1,
        characterSpacing: 0,
        fontColor: "#000000",
        backgroundColor: "",
        opacity: 1,
        strikethrough: false,
        underline: false,
        required: false,
        readOnly: false,
        fontName: "Menlo-Regular",
      },
      {
        name: "screenshot",
        type: "image",
        content: "",
        position: {
          x: 24.79,
          y: 80,
        },
        width: 160.42,
        height: 104.65,
        rotate: 0,
        opacity: 1,
        required: false,
        readOnly: false,
      },
    ],
  ],
  basePdf: {
    width: 210,
    height: 297,
    padding: [15, 15, 15, 15],
  },
  pdfmeVersion: "5.0.0",
}
