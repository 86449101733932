import request from "./api"

interface SimulationResult {
  projectId: string
  configuration: {
    numberOfTripsSampled: number
  }
  result: any
}

interface Project {
  id: string
  name: string
  createdBy?: string
  geojson: {
    "transit-edges": object
    "transit-nodes": object
    "bikes-edges": object
  }
}

export function getProject(projectId: string) {
  return request.get("/project/" + projectId)
}

export function saveProject(projectData) {
  return request.post("/project", projectData)
}

export function deleteProject(projectId: string) {
  return request.delete("/project/" + projectId)
}

export function saveSimulationResult(resultData: SimulationResult) {
  return request.post(
    "/project/" + resultData.projectId + "/result",
    resultData
  )
}

export function getSimulationResult(projectId: string) {
  return request.get("/project/" + projectId + "/result")
}

export function updateProject(projectData: Project) {
  return request.put("/project/" + projectData.id, projectData)
}

export function updateSimulationResult(simulationResult: SimulationResult) {
  return request.put(
    "/project/" + simulationResult.projectId + "/result",
    simulationResult
  )
}
