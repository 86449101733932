import React from "react"
import intl from "react-intl-universal"
import "../languages-selector/style.scss"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { getUserById, updateUserById } from "../../services/user.service"
import { PulseLoader } from "react-spinners"

interface EditDetailsFormProps {
  id: string
  closeMyProfilePopup: () => void
  setUpdatedUserDetails: (data) => void
  showNotification: (message, type) => void
}

interface EditDetailsFormState {
  userDetails: User
}

class EditDetailsForm extends React.Component<
  EditDetailsFormProps,
  EditDetailsFormState
> {
  private inputFieldDetails: any = [
    {
      name: "firstName",
      label: intl.get("label.first_name"),
      type: "text",
    },
    {
      name: "lastName",
      label: intl.get("label.last_name"),
      type: "text",
    },
    {
      name: "email",
      label: intl.get("label.email_id"),
      type: "email",
      readOnly: true,
    },
    {
      name: "organization",
      label: intl.get("label.organization"),
      type: "text",
    },
  ]

  private EditDetailsSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z]+$/, intl.get("error.firstName_only_alphabet"))
      .required(intl.get("error.required")),
    lastName: Yup.string()
      .matches(/^[A-Za-z]+$/, intl.get("error.lastName_only_alphabet"))
      .required(intl.get("error.required")),
    organization: Yup.string().required(intl.get("error.required")),
  })

  constructor(props) {
    super(props)
    this.state = {
      userDetails: {
        id: this.props.id,
        firstName: "",
        lastName: "",
        email: "",
        organization: "",
        role: "user",
      },
    }
    this.updateProfileDetails = this.updateProfileDetails.bind(this)
  }

  async componentDidMount() {
    getUserById(this.props.id)
      .then((responseData) => {
        this.setState({ userDetails: responseData.data })
      })
      .catch((error: any) => {
        // Using 'any' as a fallback
        // Check if error is an AxiosError
        console.error("API Error:", error)
      })
  }

  updateProfileDetails(values, { setSubmitting }) {
    const data: User = {
      id: this.props.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      organization: values.organization,
      role: this.state.userDetails.role,
    }
    updateUserById(data)
      .then((res) => {
        this.props.setUpdatedUserDetails(res.data[0])
        this.props.closeMyProfilePopup()
        this.props.showNotification(
          intl.get("alert.user_updated_successfully"),
          "success"
        )
      })
      .catch((err) => {
        setSubmitting(false)
      })
  }

  render() {
    const { userDetails } = this.state
    return (
      <>
        {" "}
        <Formik
          enableReinitialize
          initialValues={{
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            organization: userDetails.organization,
            email: userDetails.email,
          }}
          validationSchema={this.EditDetailsSchema}
          onSubmit={(values, { setSubmitting }) => {
            this.updateProfileDetails(values, { setSubmitting })
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="grid grid-cols-1 gap-2 gap-x-4 sm:grid-cols-2">
                {this.inputFieldDetails.map((field) => (
                  <div className="key-value-pair me-2" key={field.name}>
                    <label
                      htmlFor={field.name}
                      className="text-normal font-medium leading-6 text-gray-200"
                    >
                      {field.label}
                    </label>
                    <Field
                      name={field.name}
                      type={field.type}
                      readOnly={field.readOnly}
                      placeholder={field.label}
                      className="w-full rounded-md border-gray-300 shadow-sm"
                    />
                    {errors[field.name] && touched[field.name] && (
                      <div className="text-red-500 mt-1 text-start">
                        {errors[field.name]}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="action-buttons">
                <button
                  type="button"
                  className={"cancelModalButton"}
                  onClick={this.props.closeMyProfilePopup}
                >
                  <span>{intl.get("button.cancel")}</span>
                </button>
                <button className={"submitModalButton"} disabled={isSubmitting}>
                  {isSubmitting && (
                    <PulseLoader size={4} color="#fff" margin={1} />
                  )}
                  {isSubmitting
                    ? intl.get("button.updating")
                    : intl.get("button.update")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    )
  }
}

export default EditDetailsForm
