/* eslint-disable react-intl-universal/id-missing */
import React, { Component } from "react"
import Modal from "react-responsive-modal"
import Stepper from "react-stepper-horizontal"
import { Network, NetworkData } from "../../specs/Networks"
import PulseLoader from "react-spinners/PulseLoader"
import intl from "react-intl-universal"
import styles from "../../style.module.scss"
import {
  buildNetwork,
  downloadZip,
  runSimulation,
} from "../../services/engine.service"
import {
  deleteProject,
  getSimulationResult,
  saveProject,
  saveSimulationResult,
  updateProject,
  updateSimulationResult,
} from "../../services/project.service"
import { getAllUserProjects } from "../../services/user.service"
import { getConfigurations } from "../../services/configuration.service"
import { AppContext } from "../../pages/App"
import ConfirmationModal from "./ConfirmationPopup"
import { showNotification } from "../../utils/Toaster"
import Autosuggest from "react-autosuggest"
import Tippy from "@tippyjs/react"
import { v4 as uuid } from "uuid"
import proj4 from "proj4"
import { barcodes, image, text } from "@pdfme/schemas"
import { generate } from "@pdfme/generator"
import { template } from "./ReportTemplate"

interface RunSimulationPopupProps {
  isModalOpen: boolean
  header: string
  closeRunSimulationPopup: any
  network: Network
  networkData: NetworkData[]
  resetNetwork: any
}

interface RunSimulationPopupState {
  steps: any
  activeStep: number
  percentageOfTripSampledOption: number
  showFinalResult: boolean
  networkBuilt: boolean
  uuid: string
  statusText: string | undefined
  errorText: string | undefined
  processing: boolean
  response: any
  projectName: string
  projectLimit: number
  projectLimitWarningPopup: boolean
  apiResponseWarningPopup: boolean
  responseErrMsg: string
  isProjectSaved: boolean
  openUnsavedChangesPopup: boolean
  suggestions: any
  isSavedResult: boolean
  openUpdateProjectConfirmationPopup: boolean
  projectNameError: boolean
  networkScreenshotURL: string
  openDownloadConfirmationPopup: boolean
  isPDFDownload: boolean
  userProjectList: any
  deleteProjectsList: any
  currentSavedProjectId: string
  downloadZipProcessing: boolean
  isCoordinatesConverted: boolean
}

interface ModeData {
  trips: number
  perc_trips_by_mode: number
  dist_by_mode: number
  avg_trip_dist: number
  perc_dist_by_mode: number
  avg_trip_time: number
  avg_trip_speed: number
}

let edge_geojson: any = { type: "FeatureCollection", features: [] }
let stops_geojson: any = { type: "FeatureCollection", features: [] }
let bike_geojson: any = { type: "FeatureCollection", features: [] }

let randomNumber = 0

class RunSimulationPopup extends Component<
  RunSimulationPopupProps,
  RunSimulationPopupState
> {
  static contextType = AppContext
  private appContext: any
  private selectedProjectId: string
  private showResult: any
  private fonts: any

  constructor(props: RunSimulationPopupProps, context: any) {
    super(props)
    const urlParam = new URLSearchParams(window.location.search)
    this.selectedProjectId = urlParam.get("projectId") || ""
    this.showResult = urlParam.get("result") || false
    this.state = {
      steps: [
        {
          title: intl.get("editor.build_network"),
        },
        { title: intl.get("editor.analysis") },
      ],
      activeStep: this.showResult ? 1 : 0,
      percentageOfTripSampledOption: 5000,
      showFinalResult: false,
      networkBuilt: false,
      processing: false,
      uuid: uuid(),
      errorText: undefined,
      statusText: undefined,
      response: undefined,
      projectName: "",
      projectLimit: 0,
      projectLimitWarningPopup: false,
      apiResponseWarningPopup: false,
      responseErrMsg: "",
      isProjectSaved: false,
      openUnsavedChangesPopup: false,
      suggestions: [],
      isSavedResult: false,
      openUpdateProjectConfirmationPopup: false,
      projectNameError: false,
      networkScreenshotURL: "",
      openDownloadConfirmationPopup: false,
      isPDFDownload: false,
      userProjectList: [],
      deleteProjectsList: [],
      currentSavedProjectId: "",
      downloadZipProcessing: false,
      isCoordinatesConverted: false,
    }
    this.appContext = context
    this.onClickOfRunAnalysis = this.onClickOfRunAnalysis.bind(this)
    this.onClickBuildNetwork = this.onClickBuildNetwork.bind(this)
    this.handleFileUploadCallback = this.handleFileUploadCallback.bind(this)
    this.closeRunSimulationPopup = this.closeRunSimulationPopup.bind(this)
    this.goToConfigurationStep = this.goToConfigurationStep.bind(this)
    this.renderPDFReport = this.renderPDFReport.bind(this)
    this.downloadPDF = this.downloadPDF.bind(this)
    this.saveUserProjectWithBaseline =
      this.saveUserProjectWithBaseline.bind(this)
    this.projectLimitExceededPopupButtons =
      this.projectLimitExceededPopupButtons.bind(this)
    this.unsavedProjectPopupButtons = this.unsavedProjectPopupButtons.bind(this)
    this.getSimulationResult = this.getSimulationResult.bind(this)
    this.confirmDownload = this.confirmDownload.bind(this)
    this.downloadConfirmationBtn = this.downloadConfirmationBtn.bind(this)
    this.closeDownloadConfirmationPopup =
      this.closeDownloadConfirmationPopup.bind(this)
    this.downloadZIP = this.downloadZIP.bind(this)
    this.onClickDownloadZip = this.onClickDownloadZip.bind(this)
  }

  componentDidMount(): void {
    getConfigurations()
      .then((res) => {
        this.setState({
          projectLimit: res.data.projectLimit,
          userProjectList: this.getAllUserProjects(),
        })
      })
      .catch((err) => {
        console.error("Error:", err)
      })
    if (this.showResult) {
      this.getSimulationResult()
    }
  }

  componentDidUpdate(
    prevProps: Readonly<RunSimulationPopupProps>,
    prevState: Readonly<RunSimulationPopupState>,
    snapshot?: any
  ): void {
    if (
      this.state.userProjectList &&
      this.state.userProjectList !== prevState.userProjectList &&
      this.selectedProjectId
    ) {
      const project = this.state.userProjectList.find(
        (project) => project.id === this.selectedProjectId
      )

      const projectName = project ? project.name : ""
      this.setState({
        projectName,
      })
    }

    if (this.props !== prevProps) {
      this.setState({ uuid: uuid() })
    }
  }

  drivingTripsRange(value) {
    if (value < 125) {
      return intl.get("label.very_low")
    } else if (value < 250) {
      return intl.get("label.low")
    } else if (value < 500) {
      return intl.get("label.medium")
    } else if (value < 1000) {
      return intl.get("label.high")
    } else {
      return intl.get("label.very_high")
    }
  }

  drivingDistanceRange(value) {
    if (value < 1250) {
      return intl.get("label.very_low")
    } else if (value < 2500) {
      return intl.get("label.low")
    } else if (value < 5000) {
      return intl.get("label.medium")
    } else if (value < 10000) {
      return intl.get("label.high")
    } else {
      return intl.get("label.very_high")
    }
  }

  getSimulationResult() {
    getSimulationResult(this.selectedProjectId)
      .then((res) => {
        const data = res.data
        if (data) {
          this.setState(
            {
              isSavedResult: true,
              activeStep: 1,
              response: data.result,
              percentageOfTripSampledOption:
                data.configuration.numberOfTripsSampled,
            },
            () => {
              randomNumber = this.randomIntFromInterval(4000, 11000)
            }
          )
        }
      })
      .catch((err) => {
        console.error("Error:", err)
      })
  }

  handleNextStepClick = () => {
    const { activeStep } = this.state
    this.setState({
      activeStep: activeStep + 1,
      statusText: intl.get("editor.run_analysis"),
    })
  }

  handleNameChange = (event) => {
    const str = event.target.value
    if (!str.replace(/\s/g, "").length) {
      return
    }
    this.setState({
      projectName: event.target.value,
    })
  }

  handlePercentageOfTripSampledChange = (event) => {
    this.setState({
      percentageOfTripSampledOption: event.target.value,
    })
  }

  coordinatesConversion(from, to, geojson) {
    const updatedGeojson = {
      ...geojson,
      features: [],
    }

    geojson.features.forEach((feature) => {
      let newFeature = {
        ...feature,
        geometry: {
          ...feature.geometry,
        },
      }

      if (feature.geometry.type === "Point") {
        newFeature.geometry.coordinates = proj4(from, to).forward(
          feature.geometry.coordinates
        )
      } else {
        newFeature.geometry.coordinates = feature.geometry.coordinates.map(
          (coord) =>
            Array.isArray(coord) ? proj4(from, to).forward(coord) : coord
        )
      }
      updatedGeojson.features.push(newFeature)
    })

    // const truncatedGeojson = truncate(updatedGeojson, { precision: 5 })
    return updatedGeojson
  }

  onClickOfRunAnalysis() {
    if (this.state.projectName.trim() === "") {
      this.setState({ projectNameError: true })
      return
    }
    randomNumber = this.randomIntFromInterval(4000, 11000)
    this.setState(
      {
        processing: true,
        statusText: intl.get("editor.running_analysis"),
        errorText: undefined,
      },
      () => {
        runSimulation({
          simulation_id: this.state.uuid,
          overrides: {
            OD_SAMPLES: this.state.percentageOfTripSampledOption,
          },
        })
          .then((res: any) => {
            if (res.status == 200) {
              this.setState({
                statusText: intl.get("editor.completed"),
                processing: false,
                errorText: undefined,
                response: res.data,
              })
            } else {
              this.setState({
                statusText: intl.get("editor.run_analysis"),
                processing: false,
                errorText: res.status,
              })
            }
          })
          .catch((err) => {
            this.setState({
              processing: false,
              errorText: err.message,
              statusText: intl.get("editor.run_analysis"),
            })
          })
      }
    )
  }

  onClickBuildNetwork() {
    this.setState(
      {
        networkBuilt: false,
        statusText: intl.get("editor.uploading_files"),
        processing: true,
      },
      () => {
        this.getDelta()
        this.handleFileUploadCallback()
      }
    )
  }

  handleFileUploadCallback() {
    // if (result.status == 200) {
    this.setState({
      statusText: intl.get("editor.building_network"),
      processing: true,
    })

    buildNetwork({
      simulation_id: this.state.uuid,
      overrides: {},
      delta: {
        "transit-edges": edge_geojson.features,
        "transit-nodes": stops_geojson.features,
        "bikes-edges": bike_geojson.features,
      },
    })
      .then((res: any) => {
        if (res.status == 200) {
          this.setState({
            statusText: intl.get("editor.run_analysis"),
            processing: false,
            networkBuilt: true,
            errorText: undefined,
            activeStep: 1,
          })
        }
      })
      .catch((err) => {
        this.setState({
          statusText: intl.get("editor.build_network"),
          processing: false,
          networkBuilt: false,
          errorText: err.message,
        })
      })
  }

  onChange = (event, { newValue }) => {
    this.setState({
      projectName: newValue,
      projectNameError: newValue.trim() === "",
      currentSavedProjectId:
        this.state.userProjectList.find((project) => project.name === newValue)
          ?.id || "",
    })
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    if (inputValue.length === 0) {
      return []
    } else {
      return this.state.userProjectList.filter((project) =>
        project.name.toLowerCase().includes(inputValue)
      )
    }
  }

  renderSuggestion = (suggestion) => <div>{suggestion.name}</div>

  renderStepContent = () => {
    const {
      activeStep,
      percentageOfTripSampledOption,
      projectName,
      uuid,
      suggestions,
    } = this.state

    if (activeStep === 0) {
      return (
        <>
          {" "}
          <div className="text-center">
            <button
              className="submitModalButton button-large"
              disabled={this.state.processing}
              onClick={this.onClickBuildNetwork}
            >
              {this.state.processing ? (
                <PulseLoader size={4} color="#fff" margin={1}></PulseLoader>
              ) : null}
              {this.state.statusText
                ? this.state.statusText
                : intl.get("editor.build_network")}
            </button>
          </div>
          {!this.state.processing ? (
            <div className={"text-error"}>{this.state.errorText}</div>
          ) : null}
        </>
      )
    } else if (activeStep === 1) {
      const resultObject = this.state.response
      const baselineKeys = resultObject
        ? Object.keys(resultObject.process_result.baseline.all)
        : undefined
      const projectKeys = resultObject
        ? Object.keys(resultObject.process_result.project.all)
        : undefined
      const inputProps = {
        placeholder: intl.get("placeholder.type_project_name"),
        value: projectName,
        onChange: this.onChange,
        className: this.state.projectNameError ? "error" : "",
        maxlength: "40",
      }
      return (
        <>
          {this.state.response ? (
            <>
              {" "}
              <div className="download-btns">
                <img
                  title="Download Report"
                  src="/pdfDownload.png"
                  className={` fa me-2 ${
                    this.state.processing ? "fa-fade" : ""
                  }`}
                  onClick={
                    this.state.processing ? () => {} : this.confirmDownload
                  }
                ></img>
                <img
                  title="Download Network Representation"
                  src="/zipDownload.png"
                  className={`fa ${
                    this.state.downloadZipProcessing ? "fa-fade" : ""
                  }`}
                  onClick={
                    this.state.downloadZipProcessing
                      ? () => {}
                      : this.onClickDownloadZip
                  }
                ></img>
              </div>
              <div className={`final-result mt-3`}>
                <div className="toc-list">
                  <span className="title">
                    1. {intl.get("simulation_result_name_of_project")}
                    <span className="leaders" aria-hidden="true"></span>
                  </span>{" "}
                  <span className="result_value">
                    {projectName ? projectName : uuid}
                  </span>
                </div>
                <br></br>
                <div className="toc-list">
                  <span className="title">
                    2. {intl.get("simulation_result_config_of_simulation")}
                  </span>
                </div>
                <div className="sub-values">
                  <div className="toc-list">
                    <span className="title">
                      {intl.get("run_simulation_no_of_samples")}

                      <span className="leaders" aria-hidden="true"></span>
                    </span>{" "}
                    <span className="result_value">
                      {this.state.percentageOfTripSampledOption}
                    </span>
                  </div>
                </div>

                <br></br>
                <div className="toc-list">
                  <span className="title">
                    3. {intl.get("simulation_result_of_simulation")}
                  </span>
                </div>
                <div className="sub-values">
                  <div className="toc-list">
                    <span className="title">
                      {intl.get("run_simulation_modal_shift_in_conv_car_trips")}
                      <span className="leaders" aria-hidden="true"></span>
                    </span>{" "}
                    <span className="result_value">
                      {this.drivingTripsRange(
                        this.state.response.process_result
                          .reduction_in_driving_trips_num_100
                      )}
                    </span>
                  </div>
                  <div className="toc-list">
                    <span className="title">
                      {intl.get("run_simulation_modal_shift_in_conv_car_kms")}
                      <span className="leaders" aria-hidden="true"></span>
                    </span>{" "}
                    <span className="result_value">
                      {this.drivingDistanceRange(
                        this.state.response.process_result
                          .reduction_in_driving_distance_num_100
                      )}
                    </span>
                  </div>
                </div>
                <br></br>
                {import.meta.env.VITE_PROD === "false" ? (
                  <>
                    <p className="heading">{intl.get("label.baseline")}:</p>
                    <div className="tableDiv">
                      <table>
                        {this.renderTableHeaders(
                          baselineKeys,
                          resultObject.process_result.baseline,
                          "simulation_result"
                        )}
                        <tbody>
                          {this.renderTableRows(
                            resultObject.process_result.baseline
                          )}
                        </tbody>
                      </table>
                    </div>

                    <p className="heading">{intl.get("editor.projects")}: </p>
                    <div className="tableDiv">
                      <table>
                        {this.renderTableHeaders(
                          projectKeys,
                          resultObject.process_result.project,
                          "simulation_result"
                        )}
                        <tbody>
                          {this.renderTableRows(
                            resultObject.process_result.project
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="action-buttons">
                <button
                  className={
                    this.state.isSavedResult ? "hidden" : "cancelModalButton"
                  }
                  onClick={this.goToConfigurationStep}
                >
                  {intl.get("button.back")}
                </button>
                <Tippy
                  key={intl.get("button.save_project_result")}
                  content={intl.get("tooltip.project_saved_already")}
                  placement={"bottom"}
                  trigger={"mouseenter"}
                  className={!this.state.isProjectSaved ? "invisible" : ""}
                >
                  <button
                    className={
                      this.state.isSavedResult
                        ? "hidden"
                        : this.state.isProjectSaved
                          ? "submitModalButton disabled"
                          : "submitModalButton"
                    }
                    onClick={() => {
                      if (this.state.isProjectSaved) {
                        return
                      }
                      this.saveUserProjectWithBaseline(this.props.networkData)
                    }}
                  >
                    {intl.get("button.save_project_result")}
                  </button>
                </Tippy>

                <button
                  className="cancelModalButton"
                  onClick={this.closeRunSimulationPopup}
                >
                  {intl.get("button.close")}
                </button>
              </div>
            </>
          ) : (
            <>
              {" "}
              <fieldset disabled={this.state.processing}>
                <div className="key-value-pair resultPanel">
                  <label className="key">
                    {intl.get("run_simulation_config_name")}:{" "}
                  </label>
                  <div className="value">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={({ value }) => {
                        if (!this.selectedProjectId) {
                          this.setState({
                            suggestions: this.getSuggestions(value),
                          })
                        }
                      }}
                      onSuggestionsClearRequested={() =>
                        this.setState({ suggestions: [] })
                      }
                      getSuggestionValue={(suggestion) => suggestion.name} // Use suggestion.name as suggestion value
                      renderSuggestion={this.renderSuggestion}
                      inputProps={inputProps}
                    />
                    {this.state.projectNameError && (
                      <span className="error-message text-red-500">
                        {intl.get("error.required")}
                      </span>
                    )}
                  </div>
                </div>
                <div className="key-value-pair resultPanel">
                  <label className="key">
                    {intl.get("run_simulation_config_%_trip_sampled")}:{" "}
                  </label>
                  <div className="value">
                    <select
                      value={percentageOfTripSampledOption}
                      onChange={this.handlePercentageOfTripSampledChange}
                    >
                      <option value="5000">
                        {intl.get(
                          "run_simulation_config_%_trip_sampled_opt_6%"
                        )}
                      </option>
                      <option value="10000">
                        {" "}
                        {intl.get(
                          "run_simulation_config_%_trip_sampled_opt_11%"
                        )}
                      </option>
                      <option value="15000">
                        {" "}
                        {intl.get(
                          "run_simulation_config_%_trip_sampled_opt_16%"
                        )}
                      </option>
                      <option value="25000">
                        {intl.get(
                          "run_simulation_config_%_trip_sampled_opt_24%"
                        )}
                      </option>
                    </select>
                  </div>
                </div>
              </fieldset>
              <div className="nextButton action-buttons">
                <button
                  className="submitModalButton"
                  disabled={this.state.processing}
                  onClick={this.onClickOfRunAnalysis}
                >
                  {this.state.processing ? (
                    <PulseLoader size={4} color="#fff" margin={1}></PulseLoader>
                  ) : null}
                  {this.state.statusText
                    ? this.state.statusText
                    : intl.get("editor.run_analysis")}
                </button>
              </div>
              {!this.state.processing ? (
                <div className={"text-error"}>{this.state.errorText}</div>
              ) : null}
            </>
          )}
        </>
      )
    }

    return null
  }

  renderTableRows = (data) => {
    const prefix: string = "network_type"
    return Object.entries(data).map(([mode, modeData]) => (
      <tr key={`${mode}_${prefix}`}>
        {Object.values(modeData as ModeData).map((value, index) => (
          <td key={`${mode}_${index}_${prefix}`}>
            {index === 0
              ? intl.getHTML(`${prefix}.${mode}`)
              : intl.getHTML("simulation_result_values", { value: value })}
            {}
          </td>
        ))}
      </tr>
    ))
  }

  renderTableHeaders(keys, resultObject, prefix) {
    return (
      <thead>
        <tr>
          {keys.map((key, index) => (
            <th key={`${key}_${index}_${prefix}`}>
              {intl.get(`${prefix}_${key}`)}
            </th>
          ))}
        </tr>
      </thead>
    )
  }

  closeRunSimulationPopup() {
    if (
      this.state.isProjectSaved ||
      !this.state.response ||
      this.state.isSavedResult
    ) {
      this.props.closeRunSimulationPopup()
      setTimeout(() => {
        this.setState(
          {
            percentageOfTripSampledOption: 5000,
            showFinalResult: false,
            networkBuilt: false,
            processing: false,
            uuid: "",
            errorText: undefined,
            statusText: undefined,
            response: undefined,
            activeStep: 0,
            projectName: this.selectedProjectId ? this.state.projectName : "",
            isProjectSaved: false,
            openUnsavedChangesPopup: false,
            isSavedResult: false,
            currentSavedProjectId: "",
            isPDFDownload: false,
            isCoordinatesConverted: false,
          },
          () => {
            edge_geojson = { type: "FeatureCollection", features: [] }
            stops_geojson = { type: "FeatureCollection", features: [] }
            bike_geojson = { type: "FeatureCollection", features: [] }
          }
        )
      }, 300)
    } else {
      this.setState({
        openUnsavedChangesPopup: true,
      })
    }
  }

  goToConfigurationStep() {
    setTimeout(() => {
      this.setState({
        percentageOfTripSampledOption: this.state.percentageOfTripSampledOption,
        showFinalResult: false,
        networkBuilt: true,
        processing: false,
        errorText: undefined,
        statusText: undefined,
        response: undefined,
        activeStep: 1,
        projectName: this.state.projectName,
        isProjectSaved: false,
      })
    }, 300)
  }

  renderPDFReport(projectId: string) {
    return (
      <div className="report">
        <h2>
          {this.state.projectName ? this.state.projectName : this.state.uuid}
        </h2>
        <br />
        <ol className="toc-list" role="list">
          <li>
            <span className="title">
              {intl.get("simulation_result_config_of_simulation")}
            </span>
            <ol role="list">
              <li>
                <div>
                  <span className="title">
                    {intl.get("run_simulation_no_of_samples")}
                    <span className="leaders" aria-hidden="true"></span>
                  </span>{" "}
                  <span className="result">
                    {this.state.percentageOfTripSampledOption}
                  </span>
                </div>
              </li>
            </ol>
          </li>
          <li>
            <span className="title">
              {intl.get("simulation_result_of_simulation")}
            </span>
            <ol role="list">
              <li>
                <div>
                  <span className="title">
                    {intl.get("simulation_result_potential_modal_shift")}
                    <span className="leaders" aria-hidden="true"></span>
                  </span>{" "}
                  <span className="result">
                    {randomNumber}
                    <sup>*</sup>
                  </span>
                </div>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <b>{intl.get("note")}:</b> <sup>*</sup>
          {intl.get("simulation_note")}
        </p>
        {projectId !== "" ? <img alt="network" src={"%%imageData%%"} /> : ""}
      </div>
    )
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  confirmDownload() {
    if (this.state.networkBuilt) {
      if (!this.state.isProjectSaved) {
        this.setState({
          openDownloadConfirmationPopup: true,
        })
      } else {
        this.downloadPDF(
          this.state.currentSavedProjectId
            ? this.state.currentSavedProjectId
            : this.selectedProjectId
        )
      }
    } else if (this.selectedProjectId !== "") {
      this.downloadPDF(this.selectedProjectId)
    } else {
      this.setState({
        openDownloadConfirmationPopup: true,
      })
    }
  }

  handleProjectLimitReached() {
    const projectsToDeleteCount =
      this.state.userProjectList.length - this.state.projectLimit + 1
    const deleteProjects = this.state.userProjectList.slice(
      -projectsToDeleteCount
    )

    this.setState({
      projectLimitWarningPopup: true,
      deleteProjectsList: deleteProjects,
    })
  }

  downloadConfirmationBtn() {
    const isExistingProject = this.state.userProjectList
      ? this.state.userProjectList.some(
          (project) =>
            project.name === this.state.projectName ||
            project.id === this.selectedProjectId
        )
      : ""

    const labelContent = isExistingProject
      ? intl.get("button.update_download_with_screenshot")
      : intl.get("button.save_download_with_screenshot")

    const updateOrSaveProject = async () => {
      this.setState({
        openDownloadConfirmationPopup: false,
        processing: false,
        isPDFDownload: true,
      })

      if (isExistingProject) {
        this.updateProject()
      } else if (
        this.state.userProjectList &&
        this.state.userProjectList.length >= this.state.projectLimit
      ) {
        await this.handleProjectLimitReached()
      } else {
        await this.saveProject(this.props.networkData)
      }
    }

    return [
      {
        label: labelContent,
        buttonClass: "submitModalButton",
        action: updateOrSaveProject,
      },
      {
        label: intl.get("button.download_without_screenshot"),
        buttonClass: "submitModalButton",
        action: () => {
          this.setState(
            {
              openDownloadConfirmationPopup: false,
            },
            () => {
              this.downloadPDF("")
            }
          )
        },
      },
    ]
  }

  async downloadPDF(projectId: string) {
    const project = this.state.userProjectList.find(
      (project) => project.id === projectId
    )
    this.setState({ processing: true })
    if (this.fonts == undefined) {
      this.fonts = await this.loadFonts()
    }
    const plugins = { text, image, qrcode: barcodes.qrcode }

    try {
      const inputs = [
        {
          projectName: this.state.projectName,
          simulationConfig: intl.get("simulation_result_config_of_simulation"),
          tripsSampled: this.formatTextWithDots(
            intl.get("run_simulation_no_of_samples")
          ),
          tripsSampledValue:
            this.state.percentageOfTripSampledOption.toString(),
          simulationResult: intl.get("simulation_result_of_simulation"),
          distanceNum: this.formatTextWithDots(
            intl.get("run_simulation_modal_shift_in_conv_car_kms")
          ),
          distanceNumValue: this.drivingDistanceRange(
            this.state.response.process_result
              .reduction_in_driving_distance_num_100
          ),
          tripsNum: this.formatTextWithDots(
            intl.get("run_simulation_modal_shift_in_conv_car_trips")
          ),
          tripsNumValue: this.drivingTripsRange(
            this.state.response.process_result
              .reduction_in_driving_trips_num_100
          ),
          screenshot: project.screenshot,
        },
      ]

      const pdf = await generate({
        template,
        plugins,
        inputs,
        options: { font: this.fonts },
      })

      const blob = new Blob([pdf.buffer], { type: "application/pdf" })
      const url = URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = "report.pdf"
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
      this.setState({
        processing: false,
      })
    } catch (error) {
      this.setState({
        processing: false,
        isPDFDownload: false,
      })
    }
  }

  onClickDownloadZip() {
    this.setState(
      {
        downloadZipProcessing: true,
      },
      () => {
        if (!this.state.isCoordinatesConverted) {
          this.getDelta()
          this.setState({ isCoordinatesConverted: true })
        }
        this.downloadZIP()
      }
    )
  }

  getDelta() {
    this.props.networkData.forEach(({ baselineType, data }) => {
      if (baselineType === "Network") {
        edge_geojson.features.push(
          ...data.features.filter(
            (feature) =>
              !(feature.properties && feature.properties["mode"] === "bike") &&
              feature.properties!.status !== "baseline"
          )
        )
      } else if (baselineType === "Stops") {
        stops_geojson.features.push(
          ...data.features.filter(
            (feature) => feature.properties!.status !== "baseline"
          )
        )
      }

      bike_geojson.features.push(
        ...data.features.filter(
          (feature) =>
            feature.properties &&
            feature.properties["mode"] === "bike" &&
            feature.properties.status !== "baseline"
        )
      )
    })

    bike_geojson = this.coordinatesConversion(
      "EPSG:4326",
      "EPSG:31370",
      bike_geojson
    )

    stops_geojson = this.coordinatesConversion(
      "EPSG:4326",
      "EPSG:31370",
      stops_geojson
    )

    edge_geojson = this.coordinatesConversion(
      "EPSG:4326",
      "EPSG:31370",
      edge_geojson
    )
  }

  async downloadZIP() {
    try {
      const res = await downloadZip({
        simulation_id: this.state.uuid,
        delta: {
          "transit-edges": edge_geojson.features,
          "transit-nodes": stops_geojson.features,
          "bikes-edges": bike_geojson.features,
        },
        overrides: {},
      })

      if (res.status === 200) {
        const blob = new Blob([res.data], { type: "application/zip" })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = "simulation.zip"
        document.body.appendChild(a)
        a.click()
        a.remove()

        this.setState({ downloadZipProcessing: false })
      }
    } catch (err) {
      console.error(err)
      this.setState({ downloadZipProcessing: false })
    }
  }
  async loadFonts() {
    const fontRegular = await fetch("/fonts/Menlo-Regular.ttf").then((res) =>
      res.arrayBuffer()
    )
    const fontBold = await fetch("/fonts/Menlo-Bold.ttf").then((res) =>
      res.arrayBuffer()
    )

    return {
      "Menlo-Regular": {
        data: fontRegular,
        fallback: true,
      },
      "Menlo-Bold": {
        data: fontBold,
      },
    }
  }

  formatTextWithDots(text) {
    const maxLength = 59
    const textLength = text.length
    const remainingLength = maxLength - textLength
    const dots = ".".repeat(remainingLength)
    const formattedText = text + dots
    return formattedText.trim()
  }

  saveFile(blob, filename) {
    const a = document.createElement("a")
    document.body.appendChild(a)
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = filename
    a.click()
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }

  async saveUserProjectWithBaseline(networkData: NetworkData[]) {
    if (
      this.state.userProjectList.find(
        (project) => project.name === this.state.projectName
      ) ||
      this.state.userProjectList.find(
        (project) => project.id === this.selectedProjectId
      )
    ) {
      this.setState({
        openUpdateProjectConfirmationPopup: true,
      })
    } else if (this.state.userProjectList.length >= this.state.projectLimit) {
      await this.handleProjectLimitReached()
    } else {
      await this.saveProject(networkData)
    }
  }

  async saveProject(networkData) {
    const { id: createdBy } = this.appContext.user
    const { projectName } = this.state
    const projectPayload = {
      createdBy,
      name: projectName,
      geojson: {
        "transit-edges": edge_geojson,
        "transit-nodes": stops_geojson,
        "bikes-edges": bike_geojson,
      },
    }
    this.setState({ processing: true })
    try {
      const res = await saveProject(projectPayload)
      const projectId = res.data[0].id
      const simulationResultData = {
        projectId: projectId,
        configuration: {
          numberOfTripsSampled: this.state.percentageOfTripSampledOption,
        },
        result: this.state.response,
      }
      await saveSimulationResult(simulationResultData)
      showNotification(intl.get("alert.project_saved_successfully"), "success")

      this.setState({ isProjectSaved: true })
      if (this.state.projectLimitWarningPopup) {
        let diffCount = 0
        if (
          this.state.userProjectList &&
          this.state.userProjectList.length >= this.state.projectLimit
        ) {
          diffCount =
            this.state.userProjectList.length - this.state.projectLimit
        }

        const projectsToDelete = this.state.deleteProjectsList
        const deletionPromises = projectsToDelete.map((project) =>
          deleteProject(project.id)
        )

        await Promise.all(deletionPromises)
        projectsToDelete.forEach((project) => {
          showNotification(
            project.name +
              " - " +
              intl.get("alert.project_deleted_successfully"),
            "success"
          )
        })

        this.setState({
          projectLimitWarningPopup: false,
          openUnsavedChangesPopup: false,
          deleteProjectsList: [],
        })
      }
      await this.getAllUserProjects()
      this.setState({ processing: false, currentSavedProjectId: projectId })
      if (this.state.isPDFDownload) {
        this.downloadPDF(projectId)
      }
      this.props.resetNetwork()
    } catch (err: any) {
      if (err.response?.data?.errorCode === "23505") {
        this.setState({
          apiResponseWarningPopup: true,
          responseErrMsg: intl.get("alert.project_already_exists"),
        })
      } else {
        console.error("Error:", err)
      }
    }
  }

  projectLimitExceededPopupButtons() {
    return [
      {
        label: intl.get("button.cancel"),
        buttonClass: "cancelModalButton",
        action: () => {
          this.setState({
            projectLimitWarningPopup: false,
            processing: false,
            deleteProjectsList: [],
          })
        },
      },
      {
        label: intl.get("button.delete_and_save"),
        buttonClass: "submitModalButton",
        action: async () => {
          await this.saveProject(this.props.networkData)
        },
        processing: this.state.processing,
      },
    ]
  }

  apiResponseWarningPopupButtons() {
    return [
      {
        label: intl.get("button.ok"),
        buttonClass: "submitModalButton",
        action: () => {
          this.setState({
            apiResponseWarningPopup: false,
            responseErrMsg: "",
            processing: false,
          })
        },
      },
    ]
  }

  unsavedProjectPopupButtons() {
    return [
      {
        label: intl.get("button.ignore_and_close"),
        buttonClass: "cancelModalButton",
        action: () => {
          this.setState(
            {
              isProjectSaved: true,
            },
            () => {
              this.closeRunSimulationPopup()
            }
          )
        },
      },
      {
        label: intl.get("button.save_and_proceed"),
        buttonClass: "submitModalButton",
        action: async () => {
          await this.saveUserProjectWithBaseline(this.props.networkData)
        },
      },
    ]
  }

  updateProjectConfirmationPopupButtons() {
    return [
      {
        label: intl.get("button.update_project_result"),
        buttonClass: "submitModalButton",
        action: async () => {
          await this.updateProject()
        },
        processing: this.state.processing,
      },
      {
        label: intl.get("button.cancel"),
        buttonClass: "cancelModalButton",
        action: () => {
          this.setState({
            openUpdateProjectConfirmationPopup: false,
            processing: false,
          })
        },
      },
    ]
  }

  async updateProject() {
    let projectId = ""
    const projectByName = this.state.userProjectList.find(
      (project) => project.name === this.state.projectName
    )
    const projectById = this.state.userProjectList.find(
      (project) => project.id === this.selectedProjectId
    )
    const networkData = this.props.networkData
    if (projectByName) {
      projectId = projectByName.id
    } else if (projectById) {
      projectId = projectById.id
    }

    const data = {
      id: projectId,
      name: this.state.projectName,
      geojson: {
        "transit-edges": edge_geojson,
        "transit-nodes": stops_geojson,
        "bikes-edges": bike_geojson,
      },
    }

    this.setState({ processing: true })
    try {
      // Update project
      await updateProject(data)

      // Update simulation result
      const simulationResultData = {
        projectId: projectId,
        configuration: {
          numberOfTripsSampled: this.state.percentageOfTripSampledOption,
        },
        result: this.state.response,
      }
      await updateSimulationResult(simulationResultData)
      this.setState(
        {
          openUpdateProjectConfirmationPopup: false,
          isProjectSaved: true,
          openUnsavedChangesPopup: false,
        },
        () => {
          showNotification(
            this.state.projectName +
              " - " +
              intl.get("alert.project_updated_successfully"),
            "success"
          )
        }
      )
      this.setState({ processing: false })
      if (this.state.isPDFDownload) {
        this.downloadPDF(projectId)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  getAllUserProjects() {
    const userId = this.appContext.user.id
    getAllUserProjects(userId)
      .then((res) => {
        this.setState({
          userProjectList: res.data.reverse(),
        })
      })
      .catch((err) => {
        console.error("Error:", err)
      })
  }

  closeDownloadConfirmationPopup() {
    this.setState({ openDownloadConfirmationPopup: false })
  }

  render() {
    const { steps, activeStep } = this.state
    return (
      <div>
        <Modal
          open={this.props.isModalOpen || this.state.isSavedResult}
          onClose={this.closeRunSimulationPopup}
          center={true}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          styles={{
            modal: {
              background: "#323232",
              color: "#fff",
              minWidth: "42%",
              width: "auto",
              maxWidth: "64%",
              padding: 0,
              borderRadius: "4px",
            },
            closeButton: { top: 10, right: 5, fill: "white" },
          }}
        >
          <div className="modal-header">
            {" "}
            {this.state.response ? (
              <button
                className="btn-back"
                onClick={this.goToConfigurationStep}
                title={intl.get("button.back")}
              >
                <i className="fa-solid fa-arrow-left-long"></i>
              </button>
            ) : (
              " "
            )}
            {this.props.header}
          </div>
          <div className="modal-body">
            {this.state.isSavedResult ? (
              ""
            ) : (
              <>
                <Stepper
                  steps={steps}
                  activeStep={activeStep}
                  activeColor={styles.highlightingColor}
                  defaultColor="#eee"
                  completeColor={styles.greyColor}
                  activeTitleColor="#fff"
                  completeTitleColor="#eee"
                  defaultTitleColor="#bbb"
                  circleFontColor="#000"
                  completeBarColor={styles.greyColor}
                  size={20}
                  circleFontSize={12}
                  titleFontSize={12}
                  circleTop={10}
                  lineMarginOffset={2}
                  titleTop={4}
                />
                <hr className="divider"></hr>
              </>
            )}
            {this.renderStepContent()}
          </div>
        </Modal>
        <ConfirmationModal
          isModalOpen={this.state.projectLimitWarningPopup}
          buttons={this.projectLimitExceededPopupButtons()}
          message={`${intl.get("confirm.delete_older_project", {
            projectLimit: this.state.projectLimit,
          })}\n${this.state.deleteProjectsList.map((project, index) => `${index + 1}. ${project.name}`).join("\n")}`}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>

        <ConfirmationModal
          isModalOpen={this.state.apiResponseWarningPopup}
          buttons={this.apiResponseWarningPopupButtons()}
          message={this.state.responseErrMsg}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
        <ConfirmationModal
          isModalOpen={this.state.openUnsavedChangesPopup}
          buttons={this.unsavedProjectPopupButtons()}
          message={intl.get("confirm.unsaved_project")}
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
        <ConfirmationModal
          isModalOpen={this.state.openUpdateProjectConfirmationPopup}
          buttons={this.updateProjectConfirmationPopupButtons()}
          message={
            intl.get("confirm.update_project") +
            " " +
            this.state.projectName +
            "?"
          }
          iconClasses={"fa-solid fa-exclamation"}
        ></ConfirmationModal>
        <ConfirmationModal
          isModalOpen={this.state.openDownloadConfirmationPopup}
          buttons={this.downloadConfirmationBtn()}
          message={intl.get("confirm.download_report_with_or_without_ss")}
          iconClasses={"fa-solid fa-exclamation"}
          modalWidth="40%"
          showCloseBtn={true}
          closeConfirmationModal={this.closeDownloadConfirmationPopup}
        ></ConfirmationModal>
      </div>
    )
  }
}

export default RunSimulationPopup
