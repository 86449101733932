import { MapEventStack } from "../../specs/Mapbox"
import { NetworkData, NetworkType } from "../../specs/Networks"
import { orphanNodeCount } from "./FeaturesUtils"

function handleSave(
  draw: MapboxDraw,
  selectedNetworkType: NetworkType,
  networkData: NetworkData[],
  undoStack: MapEventStack[],
  operationMode: string,
  map: any
) {
  const updatedFeatures = draw.getAll().features
  const updatedNetworkData = [...networkData]
  let orphanNodesPresent = false
  const orphanNodes: any[] = []
  selectedNetworkType?.baselines.forEach((baselineType) => {
    const geometryType = baselineType === "Network" ? "LineString" : "Point"
    const filteredArray = updatedFeatures.filter(
      (obj) =>
        obj.geometry.type === geometryType &&
        obj.properties?.title !== "Incomplete"
    )
    const idx = updatedNetworkData.findIndex(
      (nd) =>
        nd.networkType === selectedNetworkType &&
        nd.baselineType === baselineType
    )
    const networkData = updatedNetworkData[idx]
    if (orphanNodeCount > 0) {
      orphanNodesPresent = true
    } else {
      orphanNodesPresent = false
      filteredArray.forEach((feature) => {
        const idx = networkData.data.features.findIndex(
          (d) => d.id === feature.id
        )
        if (feature.properties && feature.properties.is_orphan) {
          delete feature.properties.is_orphan
        }
        if (idx > -1) {
          networkData.data.features[idx] = feature
        } else {
          networkData.data.features.push(feature)
        }
      })

      updatedNetworkData[idx] = networkData
      undoStack.forEach((undoItem) => {
        if (
          undoItem.operation === "deleted_feature" ||
          undoItem.operation === "combine_features" ||
          undoItem.operation === "added_stop"
        ) {
          const targetFeatures = undoItem.stackState[0].oldState
          const featIdxMapping = (feat) =>
            networkData.data.features.findIndex((d) => d.id === feat.id)

          targetFeatures.forEach((feat) => {
            const featIdx = featIdxMapping(feat)
            if (featIdx > -1) {
              updatedNetworkData[idx].data.features.forEach((feature) => {
                if (
                  updatedNetworkData[idx].data.features.indexOf(feature) ===
                  featIdx
                ) {
                  feature.properties!.status = "deleted"
                }
              })
              // updatedNetworkData[idx].data.features.splice(featIdx, 1)
            }
          })
        }
      })
    }
  })

  if (orphanNodesPresent) {
    return undefined
  } else {
    return updatedNetworkData
  }
}

export { handleSave }
