import { IBuildNetwork, IDownloadZip, IRunSimulation } from "../specs/Engine"
import request from "./api"

export function buildNetwork(data: IBuildNetwork) {
  return request.post(
    "https://" + import.meta.env.VITE_ENGINE_URI + "/build_networks",
    data
  )
}

export function runSimulation(data: IRunSimulation) {
  return request.post(
    "https://" + import.meta.env.VITE_ENGINE_URI + "/run_simulation",
    data
  )
}

export function downloadZip(data: IDownloadZip) {
  return request.post(
    "https://" + import.meta.env.VITE_ENGINE_URI + "/download_geojsons",
    data,
    {
      responseType: "blob",
    }
  )
}
