import { getAllPointsOnLine } from "../turf/turfModules"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {
  GeoJsonProperties,
  NetworkGeometry,
  NetworkType,
} from "../../specs/Networks"
import {
  bboxPolygon,
  booleanDisjoint,
  booleanEqual,
  length,
  lineString,
  nearestPointOnLine,
  point,
  round,
} from "@turf/turf"
import { Feature, LineString, Point, Position } from "geojson"
import { v4 as uuid } from "uuid"
import { Map } from "mapbox-gl"
import { CRUDStack, MapEvents, OperationMode } from "../../specs/Mapbox"
import { cloneDeep } from "lodash"
import { lineSplit } from "../../../libs/line-split"

let routeSelectionValue = false
let allExistingFeatures: NetworkGeometry[] = []
let lineStringFeaturesInsideBbox: any = []
let allRoutePointFeatures: any = []
let pointsOnRoute: any[] = []
export let getAllLineStringFeaturesEditMode: NetworkGeometry[] = []
function getAllPointsAlongLine(
  currentSelection: NetworkGeometry[],
  draw: MapboxDraw,
  map: Map
) {
  const features: NetworkGeometry[] = []
  currentSelection.forEach((featureFromSelection) => {
    if (featureFromSelection.geometry.type === "LineString") {
      const lineStringGeometry = featureFromSelection.geometry as LineString
      const lineStringCoordinates = lineStringGeometry.coordinates
      const featureCollection = getAllPointsOnLine(lineStringCoordinates, draw)
      if (featureCollection.length) {
        for (const feature of featureCollection) {
          const point = map.project(feature.geometry.coordinates)
          const featuresAtPoint = draw
            .getFeatureIdsAt(point)
            .filter((id) => draw.get(id)?.geometry.type === "LineString")
          const atPointFromSelection = currentSelection
            .filter((f) => f.geometry.type === "LineString")
            .filter((f) => featuresAtPoint.includes(f.id!.toString()))
          if (featuresAtPoint.length === atPointFromSelection.length) {
            features.push(feature)
          }
        }
      }
    }
  })
  const filteredFeatures = features.filter((feature) => {
    if (feature.geometry.type !== "Point") {
      return true
    }
    return !pointsOnRoute.some((pointFeature) => pointFeature.id === feature.id)
  })

  return filteredFeatures
}

function getLineStringFeaturesInsideBbox(allFeatures, bbox, selectedRouteId) {
  return allFeatures.filter((value) => {
    if (value.properties && value.properties["title"] === "Incomplete") {
      return
    }
    return (
      !booleanDisjoint(bboxPolygon(bbox), value) &&
      value.geometry.type === "LineString" &&
      value.properties.line !== selectedRouteId
    )
  })
}

function getNearByFeatures(feature: Feature<Point>, draw: MapboxDraw) {
  const coordinate = feature.geometry.coordinates
  const displacement = 0.009
  const bbox: [number, number, number, number] = [
    coordinate[0] - displacement,
    coordinate[1] - displacement,
    coordinate[0] + displacement,
    coordinate[1] + displacement,
  ]
  return draw.getAll().features.filter((value) => {
    if (value.properties && value.properties["title"] === "Incomplete") {
      return
    }
    return (
      !booleanDisjoint(bboxPolygon(bbox), value) &&
      value.geometry.type === "LineString"
    )
  })
}

function getNearByFeaturesFrom(
  features: NetworkGeometry[],
  coordinate: number[]
) {
  const displacement = 0.009
  const bbox: [number, number, number, number] = [
    coordinate[0] - displacement,
    coordinate[1] - displacement,
    coordinate[0] + displacement,
    coordinate[1] + displacement,
  ]
  return features.filter((value) => {
    if (value.properties && value.properties["title"] === "Incomplete") {
      return
    }
    return (
      !booleanDisjoint(bboxPolygon(bbox), value) &&
      value.geometry.type === "LineString"
    )
  })
}

function snapPointToNearbyLine(
  point: Feature<Point, GeoJsonProperties>,
  draw: MapboxDraw
) {
  const nearByFeatures = getNearByFeatures(point, draw)
  const featuresWithDistances = nearByFeatures.map((f: any) =>
    nearestPointOnLine(f, point, {
      units: "meters",
    })
  )
  const minimumDistance = Math.min(
    ...featuresWithDistances.map((item) => item.properties.dist!)
  )
  if (minimumDistance > 0) {
    const closestPointOnLine = featuresWithDistances.find(
      (d) => d.properties.dist === minimumDistance
    )
    if (closestPointOnLine) {
      return closestPointOnLine.geometry.coordinates
    }
  }
  return point.geometry.coordinates
}

function getAllFeaturesWithSameRouteId(
  networkType: NetworkType,
  draw: MapboxDraw,
  selectedRouteId: string,
  actionMode: string
): NetworkGeometry[] {
  lineStringFeaturesInsideBbox = []
  allRoutePointFeatures = []
  pointsOnRoute = []
  const allFeatures = draw.getAll().features as NetworkGeometry[]
  getAllLineStringFeaturesEditMode = allFeatures.filter(
    (f) =>
      f.geometry.type === "LineString" && f.properties.title !== "Incomplete"
  )
  const filteredFeatures = getAllLineStringFeaturesEditMode
    .filter(
      (f) =>
        networkType.getPropertyFromFeature(f, "routeId") === selectedRouteId
    )
    .map((d) => d as NetworkGeometry)

  allRoutePointFeatures = allFeatures.filter(
    (f: any) => f.geometry.type === "Point"
  )
  allRoutePointFeatures.forEach((stop: any) => {
    filteredFeatures.forEach((line: any) => {
      const startCoordinates = line.geometry.coordinates[0]
      const endCoordinates =
        line.geometry.coordinates[line.geometry.coordinates.length - 1]
      const stopCoordinates = stop.geometry.coordinates

      if (
        booleanEqual(point(startCoordinates), point(stopCoordinates)) ||
        booleanEqual(point(endCoordinates), point(stopCoordinates))
      ) {
        pointsOnRoute.push(stop)
      }
    })
  })

  const concatenatedArray = filteredFeatures.concat(pointsOnRoute)
  if (actionMode !== "add_mode") {
    allFeatures.forEach((feature) => {
      if (!concatenatedArray.includes(feature)) {
        draw.delete(feature.id!.toString())
      }
    })
  }

  return filteredFeatures
}

export function calculateTimeOrSpeed(
  geometry: LineString,
  properties: GeoJsonProperties,
  divisor: string
) {
  const line = lineString(geometry.coordinates as Position[])
  const distance = length(line, { units: "kilometers" })
  return (
    distance /
    (divisor === "time_min"
      ? round(parseFloat(properties[divisor]), 1) * (1 / 60)
      : round(parseFloat(properties[divisor]), 2))
  )
}

function calculateSpeed(
  geometry: LineString | Point,
  properties: GeoJsonProperties
) {
  if (geometry.type !== "LineString") {
    return
  }
  const val = round(calculateTimeOrSpeed(geometry, properties, "time_min"), 2)
  properties["speed"] = val
  return val
}

function calculateTime(
  geometry: LineString | Point,
  properties: GeoJsonProperties,
  divisor: string = "speed"
) {
  if (geometry.type !== "LineString") {
    return
  }
  const val = round(calculateTimeOrSpeed(geometry, properties, divisor) * 60, 1)
  properties["time_min"] = val
  return val
}

function createLineStringFeature(
  coordinates: Position[],
  networkType?: NetworkType
): NetworkGeometry {
  // const line: NetworkGeometry = truncate(
  //   {
  //     id: uuid(),
  //     type: "Feature",
  //     properties: {},
  //     geometry: {
  //       type: "LineString",
  //       coordinates: coordinates,
  //     },
  //   },
  //   { precision: GEOJSON_COORD_PRECISION }
  // )
  const line: NetworkGeometry = {
    id: uuid(),
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: coordinates,
    },
  }

  setDefaultProperties(line, networkType)
  return line
}

function createPointFeature(
  coordinates: Position,
  features: NetworkGeometry[],
  networkType?: NetworkType,
  actionMode?: OperationMode
): NetworkGeometry | null {
  const pointExists = pointExistsOnMap(coordinates, features)

  if (!pointExists || (pointExists && actionMode === "extend_existing_mode")) {
    const properties =
      actionMode === "extend_existing_mode" && pointExists
        ? { title: "temperory_stop" }
        : {}

    // const addedStop: NetworkGeometry = truncate(
    //   {
    //     type: "Feature",
    //     geometry: {
    //       type: "Point",
    //       coordinates: coordinates,
    //     },
    //     properties: properties,
    //   },
    //   { precision: GEOJSON_COORD_PRECISION }
    // )

    const addedStop: NetworkGeometry = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coordinates,
      },
      properties: properties,
    }

    setDefaultProperties(addedStop, networkType)
    return addedStop
  }

  return null
}

function setDefaultProperties(
  feature: NetworkGeometry,
  networkType?: NetworkType
) {
  if (networkType) {
    const baseline =
      feature.geometry.type === "LineString"
        ? networkType.baselineProperties.Network!
        : networkType.baselineProperties.Stops!
    baseline.properties.forEach((property) => {
      if (property.defaultValue) {
        feature.properties[property.propertyName] = property.defaultValue(
          feature.geometry,
          property.propertyName,
          networkType
        )

        if (baseline.id === property.propertyName) {
          feature.id = feature.properties[property.propertyName]
        }
      }
    })
  }
}

function pointExistsOnMap(coordinates: Position, features: NetworkGeometry[]) {
  for (const feature of features) {
    if (
      feature.geometry.type === "Point" &&
      booleanEqual(point(coordinates), point(feature.geometry.coordinates))
    ) {
      return true
    }
  }
  return false
}

function getRouteSelected(isSelected: boolean) {
  routeSelectionValue = isSelected
}

function getAllRouteFeatures(draw) {
  allExistingFeatures = []
  allExistingFeatures = draw.getAll().features
}

function getAutogenerateStopsLineSplitFeatures(
  currentSelection,
  getStopsListForFeatures
) {
  let addStopOnLine = currentSelection
  const featuresToDelete: NetworkGeometry[] = []
  const updatedFeatures: NetworkGeometry[] = []
  for (const stop of getStopsListForFeatures) {
    const line = lineString(addStopOnLine.geometry.coordinates as Position[])
    const cursorAt = point(stop.geometry.coordinates)
    //truncate(point(stop.geometry.coordinates), {
    // precision: GEOJSON_COORD_PRECISION,
    //})
    // const snapped = pointOnLine(line, cursorAt)
    const featureCollection = lineSplit(line, cursorAt)

    featureCollection.features.forEach((f) => {
      f.properties = JSON.parse(JSON.stringify(addStopOnLine.properties))
      calculateTime(f.geometry, f.properties!)
      updatedFeatures.push(f as NetworkGeometry)
    })

    featuresToDelete.push(addStopOnLine)
    addStopOnLine = featureCollection.features[1] as NetworkGeometry
  }

  const lineSplitFeatures = updatedFeatures.filter(function (obj) {
    return featuresToDelete.indexOf(obj) == -1
  })

  lineSplitFeatures.forEach((f, index) => {
    let initialFeatureId = currentSelection.id
    f.properties = JSON.parse(JSON.stringify(addStopOnLine.properties))
    if (f.properties) {
      const id = initialFeatureId + "-" + (index + 1)
      initialFeatureId = id
      f.id = id
      f.properties.uid = id
    }
    calculateTime(f.geometry, f.properties!)
  })

  return lineSplitFeatures
}

function combineFeatures(this) {
  const deletedFeatures: any[] = []
  const selectedFeatures = this.draw
    .getSelected()
    .features.map((f) => f as NetworkGeometry)
  const firstSelectedFeature = cloneDeep(selectedFeatures[0])
  const coordinates: any[] = []
  const initialFeatures: NetworkGeometry[] = cloneDeep(selectedFeatures)

  selectedFeatures.forEach((feature, index) => {
    coordinates.push(feature.geometry["coordinates"])
    if (index !== 0) {
      deletedFeatures.push(feature)
    }
  })

  const featuresOnLine = getAllPointsAlongLine(
    this.state.currentSelection,
    this.draw,
    this.map
  )

  const startingCoordinate = findStartingCoordinate(coordinates)
  const sortedCoordinates = sortCoordinates(startingCoordinate, coordinates)

  const uniqueCoordinates: any[] = []

  sortedCoordinates.flat().forEach((coord) => {
    const exists = uniqueCoordinates.some(
      (existingCoord) => JSON.stringify(existingCoord) === JSON.stringify(coord)
    )
    if (!exists) {
      uniqueCoordinates.push(coord)
    }
  })

  const [firstCoord, lastCoord] = [
    uniqueCoordinates[0],
    uniqueCoordinates[uniqueCoordinates.length - 1],
  ]

  // Filter featuresOnLine based on matching coordinates with first or last coordinates
  const preservedFeatures = featuresOnLine.filter((feature) => {
    const coordinates = feature.geometry.coordinates
    const firstMatches =
      JSON.stringify(coordinates) === JSON.stringify(firstCoord)
    const lastMatches =
      JSON.stringify(coordinates) === JSON.stringify(lastCoord)
    return firstMatches || lastMatches
  })

  featuresOnLine.forEach((stop) => {
    if (!preservedFeatures.some((f) => f.id === stop.id)) {
      deletedFeatures.push(stop)
    }
  })
  this.deleteFeatures(deletedFeatures)

  firstSelectedFeature.geometry["coordinates"] = uniqueCoordinates
  firstSelectedFeature.properties["has_improvement"] = true
  firstSelectedFeature.properties["status"] =
    firstSelectedFeature.properties["status"] === "baseline"
      ? "updated"
      : "added"

  this.draw.add({
    type: "FeatureCollection",
    features: [firstSelectedFeature as NetworkGeometry],
  })

  const stackItem: CRUDStack = {
    stackState: [
      {
        oldState: deletedFeatures,
        newState: [],
        action: "delete",
      },
      {
        action: "update",
        oldState: [initialFeatures[0] as NetworkGeometry],
        newState: [firstSelectedFeature as NetworkGeometry],
      },
    ],
    operation: "combine_features",
    mode: this.state.operationMode,
  }
  this.map.fire(MapEvents.CRUD, {
    stackItem,
  })
}

function findStartingCoordinate(coordinates) {
  const firstOrLastCoordinates: any[] = []
  for (let i = 0; i < coordinates.length; i++) {
    let isUnique = true
    for (let j = 0; j < coordinates[i].length; j++) {
      const currentCoordinate = coordinates[i][j]
      for (let k = 0; k < coordinates.length; k++) {
        if (k !== i) {
          for (let l = 0; l < coordinates[k].length; l++) {
            if (
              currentCoordinate[0] === coordinates[k][l][0] &&
              currentCoordinate[1] === coordinates[k][l][1]
            ) {
              isUnique = false
              break
            }
          }
          if (!isUnique) {
            break
          }
        }
      }
      if (isUnique) {
        firstOrLastCoordinates.push(coordinates[i])
        break
      }
    }
  }

  return firstOrLastCoordinates
}

function sortCoordinates(firstOrLastCoordinates, coordinates) {
  const sortedCoordinatesArray = [firstOrLastCoordinates[0]]
  while (sortedCoordinatesArray.length < coordinates.length) {
    let added = false

    for (const currentCoordinates of coordinates) {
      if (sortedCoordinatesArray.includes(currentCoordinates)) {
        continue // Skip already sorted coordinates
      }

      const firstCoordinate = currentCoordinates[0]
      const lastCoordinate = currentCoordinates[currentCoordinates.length - 1]

      for (const sortedCoords of sortedCoordinatesArray) {
        const sortedFirst = sortedCoords[0]
        const sortedLast = sortedCoords[sortedCoords.length - 1]

        if (
          (firstCoordinate[0] === sortedFirst[0] &&
            firstCoordinate[1] === sortedFirst[1]) ||
          (firstCoordinate[0] === sortedLast[0] &&
            firstCoordinate[1] === sortedLast[1]) ||
          (lastCoordinate[0] === sortedFirst[0] &&
            lastCoordinate[1] === sortedFirst[1]) ||
          (lastCoordinate[0] === sortedLast[0] &&
            lastCoordinate[1] === sortedLast[1])
        ) {
          sortedCoordinatesArray.push(currentCoordinates)
          added = true
          break
        }
      }

      if (added) {
        break
      }
    }

    if (!added) {
      break // If no new coordinates added, break the loop
    }
  }

  return sortedCoordinatesArray
}

function checkCoordinateMatch(this) {
  const selectedFeatures = this.draw
    .getSelected()
    .features.map((f) => f as NetworkGeometry)
  const coordinates: any[] = []

  selectedFeatures.forEach((feature) => {
    coordinates.push(feature.geometry["coordinates"])
  })

  const coordinateSet = new Set()

  // Iterate over each set of coordinates
  for (let i = 0; i < coordinates.length; i++) {
    // Iterate over each coordinate in the current set
    for (let j = 0; j < coordinates[i].length; j++) {
      const coordinateStr = coordinates[i][j].slice(0, 2).join(",")
      // Check if the coordinate already exists in the set
      if (coordinateSet.has(coordinateStr)) {
        return true
      } else {
        coordinateSet.add(coordinateStr)
      }
    }
  }

  this.setState({ mismatchedCoordinates: true })
  return false
}

// check if feature is directly or indirectly connected to existing feature
function isConnectedToExisting(feature: any, existingFeatures, allFeatures) {
  const visitedFeatures = new Set<any>()
  const connectedFeaturesArray: any[] = [feature]

  for (const currentFeature of connectedFeaturesArray) {
    if (visitedFeatures.has(currentFeature)) {
      continue
    }

    visitedFeatures.add(currentFeature)

    if (isConnectedToExistingDirectly(currentFeature, existingFeatures)) {
      return true
    }

    // Push connected features to the connectedFeaturesArray that haven't been visited yet
    for (const connectedFeature of getConnectedFeatures(
      currentFeature,
      allFeatures
    )) {
      if (!visitedFeatures.has(connectedFeature)) {
        connectedFeaturesArray.push(connectedFeature)
      }
    }
  }

  return false
}

// check if feature is directly connected to existing feature
function isConnectedToExistingDirectly(feature: any, existingFeatures) {
  const coordinates = feature.geometry.coordinates
  for (const coordinate of coordinates) {
    for (const existingFeature of existingFeatures) {
      if (isConnectedToFeature(coordinate, existingFeature)) {
        return true
      }
    }
  }
  return false
}

// check if a feature is connected to existing feature
function isConnectedToFeature(coordinate: any, feature: any) {
  const existingCoordinates = feature.geometry.coordinates
  for (const existingCoordinate of existingCoordinates) {
    if (JSON.stringify(coordinate) === JSON.stringify(existingCoordinate)) {
      return true
    }
  }
  return false
}

// get features connected to a given feature
function getConnectedFeatures(feature: any, allFeatures) {
  const connectedFeatures: any[] = []
  for (const otherFeature of allFeatures) {
    if (areConnected(feature, otherFeature)) {
      connectedFeatures.push(otherFeature)
    }
  }
  return connectedFeatures
}

// check if two features are connected
function areConnected(feature1: any, feature2: any) {
  const coordinates1 = feature1.geometry.coordinates
  const coordinates2 = feature2.geometry.coordinates
  for (const coordinate1 of coordinates1) {
    for (const coordinate2 of coordinates2) {
      if (JSON.stringify(coordinate1) === JSON.stringify(coordinate2)) {
        return true
      }
    }
  }
  return false
}

export let orphanNodeCount = 0
// find Disconnected features
function getDisconnectedFeatures(
  draw: any,
  existingFeatures: any[],
  selectedNetworkType,
  operationMode,
  map
) {
  const allFeatures = draw.getAll().features
  const DisconnectedLineStringFeatures: any[] = []
  for (const feature of allFeatures) {
    if (!isConnectedToExisting(feature, existingFeatures, allFeatures)) {
      DisconnectedLineStringFeatures.push(feature)
    }
  }

  const filteredDisconnectedLineStringFeatures =
    DisconnectedLineStringFeatures.filter((f) => {
      return (
        f.geometry.type === "LineString" && f.properties.title !== "Incomplete"
      )
    })

  filteredDisconnectedLineStringFeatures.forEach((feature) => {
    setTimeout(() => {
      if (operationMode === "add_mode") {
        feature.properties.is_disconnected = "true"
        draw.delete(feature.id)
        draw.add(feature)
      } else {
        feature.properties.is_disconnected = "true"
        draw.setFeatureProperty(feature.id, "is_disconnected", "true")
        draw.add(feature)
      }
    }, 100)
  })

  draw
    .getAll()
    .features.filter(
      (f) =>
        f.geometry.type === "LineString" && f.properties?.title !== "Incomplete"
    )
    .forEach((feature) => {
      const found = filteredDisconnectedLineStringFeatures.find(
        (d) => d.id === feature.id
      )
      if (
        found === undefined &&
        feature.properties &&
        feature.properties.is_disconnected
      ) {
        delete feature.properties.is_disconnected
        draw.delete(feature.id)
        draw.add(feature)
      }
    })
  orphanNodeCount = filteredDisconnectedLineStringFeatures.length
  return filteredDisconnectedLineStringFeatures
}

// get features with more than 2 endPoints
function getDisconnectedEndPoints(
  draw,
  getAllExistingFeartures,
  selectedNetworkType,
  operationMode,
  map
) {
  const firstAndLastCoordinates: any[] = []
  let endPoints: any[] = []
  const allFeatures = draw
    .getAll()
    .features.filter(
      (f) =>
        f.geometry.type === "LineString" && f.properties?.title !== "Incomplete"
    )
    .map((f) => f as NetworkGeometry)

  allFeatures.forEach((d) => {
    const coordinates = d.geometry.coordinates
    const firstCoordinate = coordinates[0]
    const lastCoordinate = coordinates[coordinates.length - 1]
    firstAndLastCoordinates.push([firstCoordinate, lastCoordinate])
  })

  const flattenedArray = firstAndLastCoordinates.flat()
  const coordCounts = {}

  flattenedArray.forEach((coord) => {
    const key = JSON.stringify(coord)
    coordCounts[key] = (coordCounts[key] || 0) + 1
  })

  endPoints = flattenedArray.filter((coord, index) => {
    return (
      flattenedArray.findIndex(
        (c, i) => i !== index && c[0] === coord[0] && c[1] === coord[1]
      ) === -1
    )
  })

  if (endPoints.length > 2) {
    // setTimeout(() => {
    allFeatures.forEach((feature) => {
      feature.properties.is_disconnected = "true"
      draw.setFeatureProperty(feature.id, "is_disconnected", "true")
      draw.add(feature)

      const stackItem = {
        mode: "add_mode",
        operation: "route_selection",
        features: allFeatures,
        properties: {
          routeId: allFeatures[0].properties.line,
          is_disconnected: true,
        },
      }
      map.fire(MapEvents.ROUTE_SELECTED, {
        stackItem,
      })
      // }, 100)
    })
    orphanNodeCount = allFeatures.length
  } else if (endPoints.length === 2 && allFeatures.length === 1) {
    getAllExistingFeartures = getAllExistingFeartures.filter(
      (f) => f.properties.line !== allFeatures[0].properties.line
    )
    const updatedFeatures = getDisconnectedFeatures(
      draw,
      getAllExistingFeartures,
      selectedNetworkType,
      operationMode,
      map
    )

    if (orphanNodeCount === 1) {
      // setTimeout(() => {
      const stackItem = {
        mode: "add_mode",
        operation: "route_selection",
        features: updatedFeatures,
        properties: {
          routeId: updatedFeatures[0].properties.line,
          is_disconnected: true,
        },
      }
      map.fire(MapEvents.ROUTE_SELECTED, {
        stackItem,
      })
      // }, 100)
    }
  } else {
    allFeatures
      .filter(
        (f) =>
          f.geometry.type === "LineString" &&
          f.properties?.title !== "Incomplete"
      )
      .forEach((feature) => {
        if (feature.properties && feature.properties.is_disconnected) {
          delete feature.properties.is_disconnected
          draw.delete(feature.id)
          draw.add(feature)
        }
      })
    // setTimeout(() => {
    const stackItem = {
      mode: "add_mode",
      operation: "route_selection",
      features: allFeatures,
      properties: {
        routeId: allFeatures[0].properties.line,
        is_disconnected: true,
      },
    }
    map.fire(MapEvents.ROUTE_SELECTED, {
      stackItem,
    })
    // }, 100)
    orphanNodeCount = 0
  }
  return endPoints
}

// check if features are Disconnected
function checkDisconnectedFeatures(
  draw,
  getAllExistingFeartures,
  operationMode,
  selectedNetworkType,
  map
) {
  if (selectedNetworkType.name !== "bike") {
    if (operationMode === "add_mode") {
      getDisconnectedFeatures(
        draw,
        getAllExistingFeartures,
        selectedNetworkType,
        operationMode,
        map
      )
    } else {
      getDisconnectedEndPoints(
        draw,
        getAllExistingFeartures,
        selectedNetworkType,
        operationMode,
        map
      )
    }
  }
}

function getFeaturesByRouteId(
  networkType: NetworkType,
  draw: MapboxDraw,
  selectedRouteId: string
): {
  featuresOnRoute: NetworkGeometry[]
  featuresNotOnRoute: NetworkGeometry[]
} {
  const featuresAll = draw.getAll().features as NetworkGeometry[]
  const linesAll = featuresAll.filter(
    (f) =>
      f.geometry.type === "LineString" && f.properties.title !== "Incomplete"
  )
  const linesOnRoute = linesAll
    .filter(
      (f) =>
        networkType.getPropertyFromFeature(f, "routeId") === selectedRouteId
    )
    .map((d) => d as NetworkGeometry)

  const pointsAll = featuresAll.filter((f: any) => f.geometry.type === "Point")
  const pointsMap: { [key: string]: NetworkGeometry } = {}
  pointsAll.forEach((d) => {
    pointsMap[d.geometry.coordinates.join()] = d
  })

  const pointsOnRoute: NetworkGeometry[] = []
  linesOnRoute.forEach((line) => {
    const startCoordinates = line.geometry.coordinates[0] as Position
    const endCoordinates = line.geometry.coordinates[
      line.geometry.coordinates.length - 1
    ] as Position

    if (pointsMap[startCoordinates.join()] != undefined) {
      pointsOnRoute.push(pointsMap[startCoordinates.join()])
    }

    if (pointsMap[endCoordinates.join()] != undefined) {
      pointsOnRoute.push(pointsMap[endCoordinates.join()])
    }
  })

  const linesIdOnRoute = linesOnRoute.map((f) => f.id!.toString())
  const pointsIdOnRoute = pointsOnRoute.map((f) => f.id!.toString())

  return {
    featuresOnRoute: [...linesOnRoute, ...pointsOnRoute],
    featuresNotOnRoute: [
      ...linesAll.filter((f) => !linesIdOnRoute.includes(f.id!.toString())),
      ...pointsAll.filter((f) => !pointsIdOnRoute.includes(f.id!.toString())),
    ],
  }
}

export {
  getAllPointsAlongLine,
  getNearByFeatures,
  getNearByFeaturesFrom,
  snapPointToNearbyLine,
  getAllFeaturesWithSameRouteId,
  calculateSpeed,
  calculateTime,
  createPointFeature,
  createLineStringFeature,
  getRouteSelected,
  routeSelectionValue,
  getAutogenerateStopsLineSplitFeatures,
  combineFeatures,
  checkCoordinateMatch,
  getAllRouteFeatures,
  allExistingFeatures,
  getDisconnectedFeatures,
  getDisconnectedEndPoints,
  checkDisconnectedFeatures,
  getFeaturesByRouteId,
}
