import "tippy.js/dist/tippy.css"
import "tippy.js/themes/light.css"
import { Baseline, NetworkGeometry, NetworkType } from "./Networks"
import MapboxDraw, {
  DrawCustomModeThis,
  DrawFeature,
  DrawLineString,
} from "@mapbox/mapbox-gl-draw"
import { LngLat, LngLatLike, MapMouseEvent } from "mapbox-gl"
import { Position } from "geojson"

type OperationMode =
  | "add_mode"
  | "extend_existing_mode"
  | "edit_mode"
  | "unknown"

type CRUDOperation =
  | "added_stop"
  | "added_control_point"
  | "added_network_with_stop"
  | "added_shortest_path"
  | "added_autogenerated_stop"
  | "updated_coordinates"
  | "updated_properties"
  | "deleted_feature"
  | "combine_features"
  | "deleted_control_point"

type SelectionOperation = "selection_change" | "route_selection"

type StackAction = "update" | "create" | "delete"
export const MapEvents = {
  CRUD: "crud",
  SELECTION_CHANGE: "selection_change",
  ROUTE_SELECTED: "route_selected",
  ROUTE_DESELECTED: "route_deselected",
}

type MapEventStack = CRUDStack | SelectStack

interface CRUDStack extends BaseStack {
  operation: CRUDOperation
  stackState: StackState[]
}

interface SelectStack extends BaseStack {
  operation: SelectionOperation
  features: NetworkGeometry[]
  properties: { [key: string]: any }
}

interface BaseStack {
  mode: OperationMode
}

interface StackState {
  action: StackAction
  newState: NetworkGeometry[]
  oldState: NetworkGeometry[]
  newSelection?: NetworkGeometry[]
  oldSelection?: NetworkGeometry[]
}

export interface CustomControl {
  action?: (e) => void
  mode?: string
  classes?: string
  conditionalClasses?: () => string
  title?: string
  isToggleButton?: boolean
  defaultActive?: boolean
  iconClass: string
  dataTooltip: string
  visible?: boolean
  label?: string
}

type EditModeState = {
  feature: DrawFeature | undefined
  dragMoveLocation?: LngLat
  dragMoving: boolean
  canDragMove: boolean
  actionMode: OperationMode
  isRouteSelected: boolean
  selectedCoordPaths: Array<{ coord_path: string; feature_id: string }>
  selectedNetworkType: NetworkType
  featureOrg: { [key: string]: NetworkGeometry }
  featuresAtPoint: FeaturesAtPoint[]
  shiftPressed: boolean
  selectedFeatures: DrawFeature[]
  routeFeatureIds: string[]
}

interface FeaturesAtPoint {
  feature: NetworkGeometry
  coordIdx: number
}

type EditModeOptions = {
  featureId?: string
  startPos?: LngLatLike | null
  actionMode: OperationMode
  selectedNetworkType: NetworkType
  event?: MapMouseEvent
}

interface AddModeState {
  // check if required
  map: any

  // currently drawn line
  line: DrawLineString

  // check use
  currentVertexPosition: number

  // check if can be used for position
  vertices: Position[]

  // master list of all points
  snapList: NetworkGeometry[]

  // check
  selectedFeatures: DrawFeature[]

  // check
  direction: "forward" | "backward"

  // stops
  startCords: Position

  // stops
  endCords: Position

  // check usage
  completeLineCoordinates: Position[][]

  // check
  options: any

  // current snap lot
  snappedLng: number

  // current snap lng
  snappedLat: number

  // last vertex
  lastVertex: Position

  // callback
  onCancel: () => void

  // selected network type
  selectedNetworkType: NetworkType

  // baseline info
  network?: Baseline

  // baseline info
  stop?: Baseline
}

interface AddModeOptions {
  actionMode: OperationMode
  selectedNetworkType: NetworkType
  init: boolean
}

interface IAddMode
  extends MapboxDraw.DrawCustomMode<AddModeState, AddModeOptions> {
  drawLine(
    this: DrawCustomModeThis & this,
    state: AddModeState,
    isLeftClick: boolean
  ): void

  snapLineToRoad(
    this: DrawCustomModeThis & this,
    state: AddModeState,
    e: MapMouseEvent
  ): void

  shortestPath(
    this: DrawCustomModeThis & this,
    state: AddModeState,
    callback: () => void
  ): void

  getShortestPath(start: Position, end: Position): Promise<any>
}

interface ExtendExistingModeState extends EditModeState {
  map: any
  line: DrawLineString
  currentVertexPosition: number
  vertices: Position[]
  snapList: NetworkGeometry[]
  direction: "forward" | "backward"
  startCords: Position
  endCords: Position
  completeLineCoordinates: Position[][]
  options: any
  snappedLng: number
  snappedLat: number
  lastVertex: Position
  onCancel: () => void
  network?: Baseline
  stop?: Baseline
  routeFeatures: NetworkGeometry[]
  routeId: any
}

interface ExtendExistingModeOptions {
  actionMode: OperationMode
  selectedNetworkType: NetworkType
  init: boolean
  featureId?: string
  event?: MapMouseEvent
}

interface IExtendExistingMode
  extends MapboxDraw.DrawCustomMode<
    ExtendExistingModeState,
    ExtendExistingModeOptions
  > {
  drawLine(
    this: DrawCustomModeThis & this,
    state: ExtendExistingModeState,
    isLeftClick: boolean
  ): void

  snapLineToRoad(
    this: DrawCustomModeThis & this,
    state: ExtendExistingModeState,
    e: MapMouseEvent
  ): void

  shortestPath(
    this: DrawCustomModeThis & this,
    state: ExtendExistingModeState,
    callback: () => void
  ): void

  getShortestPath(start: Position, end: Position): Promise<any>

  fireActionable(
    this: DrawCustomModeThis & this,
    state: ExtendExistingModeState
  ): void

  fireUpdate(
    this: DrawCustomModeThis & this,
    state: ExtendExistingModeState,
    oldState?: NetworkGeometry[],
    newState?: NetworkGeometry[]
  ): void

  handleDraw(
    this: DrawCustomModeThis & this,
    state: ExtendExistingModeState
  ): void

  startDraw(
    this: DrawCustomModeThis & this,
    state: ExtendExistingModeState,
    e: any
  ): void

  getEndPoints(): void
}

export type {
  StackAction,
  EditModeOptions,
  EditModeState,
  IAddMode,
  AddModeState,
  AddModeOptions,
  StackState,
  MapEventStack,
  CRUDStack,
  SelectStack,
  OperationMode,
  CRUDOperation,
  SelectionOperation,
  FeaturesAtPoint,
  ExtendExistingModeOptions,
  ExtendExistingModeState,
  IExtendExistingMode,
}
