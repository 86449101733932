import { Map } from "mapbox-gl"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {
  BaselineType,
  getStyleForLayer,
  Network,
  NetworkData,
  NetworkType,
} from "../../specs/Networks"
import { MapboxInfoBoxControl } from "mapbox-gl-infobox"
import React from "react"
import ReactDomServer from "react-dom/server"
import intl from "react-intl-universal"
import { generateLayerId, generateSourceId } from "./Layers"
import EditMode from "../../components/mapbox/EditMode"
import AddMode from "../../components/mapbox/AddMode"
import ExtendExistingMode from "../../components/mapbox/ExtendExistingMode"
import styles from "../../style.module.scss"
import Color from "color"

const default_line_layout = {
  "line-cap": "round",
  "line-join": "round",
  "line-sort-key": [
    "case",
    [
      "all",
      ["==", ["get", "user_status"], "baseline"],
      ["==", ["get", "route_mode"], "false"],
    ],
    1,
    ["==", ["get", "route_mode"], "true"],
    4,
    2,
  ],
}
const default_line_paint = {
  "line-width": [
    "case",
    // Check if user_mode is "bus"
    ["==", ["get", "user_mode"], "bus"],
    4,
    // Check if user_mode is "bike"
    ["==", ["get", "user_mode"], "bike"],
    2,
    // Default value if user_mode is not "bus" or "bike"
    5,
  ],
}

interface InfoBoxOptions {
  layerId: string
  formatter: (properties: any) => string
}

function buildLayersForNetwork(
  map: Map,
  network: Network,
  networkData: NetworkData[],
  lightTheme: boolean = false
) {
  const layerIds: string[] = []
  const sourceIds: string[] = []
  network.networkTypes.forEach((networkType) => {
    networkType.baselines.forEach((baselineType) => {
      const sourceId: string = generateSourceId(networkType, baselineType)
      const source = map.getSource(sourceId)
      if (source == undefined) {
        sourceIds.push(sourceId)
        let data = networkData.find(
          (d) => d.networkType === networkType && d.baselineType == baselineType
        )?.data

        // If networkType is "bike", set data to an empty array
        if (networkType.name === "bike") {
          data = {
            type: "FeatureCollection",
            features: [],
          }
        }

        map.addSource(sourceId, {
          type: "geojson",
          data: data,
        })
      } else {
        if (source.type === "geojson") {
          const data = networkData.find(
            (d) =>
              d.networkType === networkType && d.baselineType == baselineType
          )?.data
          source.setData(data as GeoJSON.FeatureCollection<GeoJSON.Geometry>)
        }
      }
      const layerId = generateLayerId(networkType, baselineType)
      if (map.getLayer(layerId) == undefined) {
        layerIds.push(layerId)
        map.addLayer({
          ...getStyleForLayer(
            layerId,
            networkType,
            baselineType,
            sourceId,
            lightTheme
          ),
          source: sourceId,
        })
        //addPopUp(map, layerId, baselineType, networkType)
      }
    })
  })
  return { layerIds, sourceIds }
}

function drawingControl() {
  return new MapboxDraw({
    displayControlsDefault: false,
    modes: {
      ...MapboxDraw.modes,
      edit_mode: EditMode,
      add_mode: AddMode,
      extend_existing_mode: ExtendExistingMode,
    },
    userProperties: true,
    // Styling guides
    styles: [
      // LINE STYLING
      {
        id: "gl-draw-line",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["!=", "active", "true"],
        ],
        layout: { ...default_line_layout },
        paint: {
          ...default_line_paint,
          "line-color": [
            "case",
            [
              "all",
              ["==", ["get", "user_is_disconnected"], "true"],
              ["==", ["get", "route_mode"], "true"],
            ],
            "#FF0000",
            [
              "all",
              ["==", ["get", "route_mode"], "true"],
              [
                "any",
                ["==", ["get", "user_status"], "added"],
                ["==", ["get", "user_status"], "updated"],
              ],
            ],
            "#28a745",
            ["==", ["get", "hovered"], "true"],
            Color(styles.highlightingColor).lighten(0.3).hex(),
            ["==", ["get", "route_mode"], "false"],
            Color(styles.highlightingColor).lighten(0.9).hex(),
            "#FFFFFF",
          ],
        },
      },
      {
        id: "gl-draw-line-active",
        type: "line",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "active", "true"],
        ],
        layout: { ...default_line_layout },
        paint: {
          ...default_line_paint,
          "line-color": styles.highlightingColor,
        },
      },

      // VERTEX
      {
        id: "gl-draw-line-vertex",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["==", "active", "false"],
        ],
        paint: {
          "circle-radius": 3,
          "circle-color": "#FFFFFF",
        },
        layout: {
          "circle-sort-key": 10,
        },
      },
      {
        id: "gl-draw-line-vertex-active",
        type: "circle",
        filter: [
          "all",
          ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["==", "active", "true"],
        ],
        paint: {
          "circle-color": styles.highlightingColor,
          "circle-radius": 3,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#FFFFFF",
        },
        layout: {
          "circle-sort-key": 15,
        },
      },

      // POINT
      {
        id: "gl-draw-point",
        type: "circle",
        filter: ["all", ["==", "$type", "Point"], ["!=", "meta", "vertex"]],
        paint: {
          "circle-color": [
            "case",
            ["==", "active", "true"],
            styles.highlightingColor,
            "#303030",
          ],
          "circle-radius": [
            "case",
            ["==", ["get", "user_mode"], "bus"],
            3,
            ["==", ["get", "user_is_endPoint"], "true"],
            5,
            4,
          ],
          "circle-stroke-color": [
            "case",
            ["==", ["get", "user_is_endPoint"], "true"],
            Color(styles.highlightingColor).alpha(0.5).lighten(0.3).hex(),
            ["==", ["get", "hovered"], "true"],
            Color(styles.highlightingColor).alpha(0.5).lighten(0.3).hex(),
            ["==", ["get", "route_mode"], "false"],
            "#303030",
            "#FFFFFF",
          ],
          "circle-stroke-width": [
            "case",
            ["==", ["get", "user_is_endPoint"], "true"],
            3,
            ["==", ["get", "hovered"], "true"],
            3,
            2,
          ],
        },
        layout: {
          "circle-sort-key": [
            "case",
            ["==", ["get", "route_mode"], "true"],
            15,
            10,
          ],
        },
      },
      {
        id: "gl-draw-point-active",
        type: "circle",
        filter: [
          "all",
          ["==", "$type", "Point"],
          ["!=", "meta", "vertex"],
          ["!=", "meta", "midpoint"],
          ["==", "active", "true"],
        ],
        paint: {
          "circle-color": styles.highlightingColor,
          "circle-radius": 5,
          "circle-stroke-width": 3,
          "circle-stroke-color": "#FFFFFF",
        },
        layout: {
          "circle-sort-key": 20,
        },
      },
    ],
  })
}

let infoBoxControl: MapboxInfoBoxControl | null = null // Reference to the MapboxInfoBoxControl

function addPopUp(
  map: Map,
  layerId: string,
  baselineType: BaselineType,
  networkType: NetworkType
) {
  const infoboxOptions: InfoBoxOptions = {
    layerId: "", // Initialize with an empty layerId
    formatter: (properties) => {
      return properties
        ? generateHTML(properties, networkType, baselineType)
        : ""
    },
  }

  map.on("mouseenter", layerId, (e) => {
    if (e.features && e.features.length > 0 && !infoBoxControl) {
      infoboxOptions.layerId = layerId // Update the layerId in infoboxOptions
      infoBoxControl = new MapboxInfoBoxControl(infoboxOptions)
      map.addControl(infoBoxControl as any)
    }
  })
  map.on("mouseout", layerId, () => {
    if (infoBoxControl) {
      map.removeControl(infoBoxControl as any)
      infoBoxControl = null
    }
  })
}

function generateHTML(
  properties: {
    [key: string]: any
  },
  networkType: NetworkType,
  baselineType: BaselineType
): string {
  const ele: any = (
    <>
      <div className="infobox_title">
        <span>
          <i
            style={{ color: networkType.color }}
            className="fa-solid fa-circle infobox-network-type"
          />
        </span>
        <b>
          {intl.get("network_type." + networkType.name)} - {baselineType}
        </b>
      </div>
      <table className="propertyDetailsTable">
        <tbody>
          {networkType.baselineProperties[baselineType]?.properties.map(
            (property) => {
              return (
                <tr key={property.propertyName}>
                  <th style={{ textAlign: "left" }}>
                    {intl.get("properties." + property.localeKey)}
                  </th>
                  {property.datatype === "boolean" ? (
                    <td>
                      {properties[property.propertyName] == "0"
                        ? "False"
                        : "True"}
                    </td>
                  ) : (
                    <td>{properties[property.propertyName]}</td>
                  )}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </>
  )
  return ReactDomServer.renderToString(ele)
}

export { addPopUp, buildLayersForNetwork, drawingControl, generateHTML }
