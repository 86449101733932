import MapboxDraw, {
  DrawCustomModeThis,
  DrawFeature,
  DrawLineString,
  MapMouseEvent,
} from "@mapbox/mapbox-gl-draw"
import React from "react"
import {
  CRUDOperation,
  CRUDStack,
  EditModeState,
  ExtendExistingModeState,
  FeaturesAtPoint,
  MapEvents,
  OperationMode,
  SelectStack,
  StackAction,
} from "../../specs/Mapbox"
import { NetworkGeometry } from "../../specs/Networks"
import {
  calculateTime,
  createPointFeature,
  getAllFeaturesWithSameRouteId,
  getAllRouteFeatures,
  getFeaturesByRouteId,
} from "../../utils/mapbox/FeaturesUtils"
import ReactDomServer from "react-dom/server"
import {
  booleanEqual,
  clone,
  lineString,
  nearestPointOnLine,
  point,
} from "@turf/turf"
import { snap } from "../../utils/mapbox/Snapping"
import mapboxgl from "mapbox-gl"
import { lineSplit } from "../../../libs/line-split"

const CommonSelectors = MapboxDraw.lib.CommonSelectors,
  { isActiveFeature, isInactiveFeature, isOfMetaType, noTarget, isFeature } =
    CommonSelectors,
  Constants = MapboxDraw.constants,
  isVertex = isOfMetaType(Constants.meta.VERTEX),
  isMidpoint = isOfMetaType(Constants.meta.MIDPOINT),
  { geojsonTypes, cursors } = MapboxDraw.constants,
  { createVertex, createSupplementaryPoints } = MapboxDraw.lib
export const popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
})
type Position = number[]
const div = document.createElement("div")
let routesAtPoint: string[] = []

function renderRouteContextMenu(
  this: DrawCustomModeThis,
  state: EditModeState | ExtendExistingModeState,
  e: MapMouseEvent,
  features: NetworkGeometry[],
  draw: MapboxDraw
) {
  popup.remove()
  const featuresAt = features.filter(
    (x, i, a) =>
      a.findIndex(
        (y) =>
          state.selectedNetworkType.getPropertyFromFeature(x, "routeId") ===
          state.selectedNetworkType.getPropertyFromFeature(y, "routeId")
      ) === i
  )

  if (featuresAt.length > 0) {
    const element = getContextMenuForFeatures(state, featuresAt)
    div.innerHTML = ReactDomServer.renderToString(element)
    div.querySelectorAll(".context-menu-item").forEach((e: Element) => {
      e.addEventListener("click", (event: any) => {
        popup.remove()
        const featureId = event.currentTarget.id
        const feat = this.getFeature(featureId)
        selectAndRenderRouteFeatures.call(
          this,
          state,
          feat.toGeoJSON() as NetworkGeometry,
          featuresAt,
          draw
        )
      })
    })
    popup.setLngLat(e.lngLat).setDOMContent(div).addTo(this.map)
  }

  if (state.actionMode === "extend_existing_mode") {
    getAllRouteFeatures(draw)
  }
}

function highlightRouteFeatures(
  this: DrawCustomModeThis,
  draw: MapboxDraw,
  state: EditModeState,
  feature: DrawFeature,
  highligth: boolean
) {
  const selectedNetworkType = state.selectedNetworkType
  const routeId = selectedNetworkType.getPropertyFromFeature(
    feature.toGeoJSON() as NetworkGeometry,
    "routeId"
  )
  const allFeaturesForRoute = draw
    .getAll()
    .features.filter(
      (f) =>
        selectedNetworkType.getPropertyFromFeature(
          f as NetworkGeometry,
          "routeId"
        ) == routeId
    )
}

function getContextMenuForFeatures(
  state: EditModeState | ExtendExistingModeState,
  features: NetworkGeometry[]
) {
  const element = (
    <div id="contextMenu" className="context-menu">
      <div className="elementContainer">
        {features
          .sort(
            (a, b) =>
              Number(
                state.selectedNetworkType.getPropertyFromFeature(a, "routeId")
              ) -
              Number(
                state.selectedNetworkType.getPropertyFromFeature(b, "routeId")
              )
          )
          .map((feature) => {
            return (
              <div
                id={feature.id!.toString()}
                key={feature.id}
                className={`context-menu-style context-menu-item`}
              >
                <i
                  className={`fa-solid ${
                    feature.geometry.type === "Point"
                      ? "fa-circle-dot"
                      : "fa-road"
                  }`}
                />
                {state.selectedNetworkType.getPropertyFromFeature(
                  feature,
                  "routeId"
                ) +
                  " " +
                  state.selectedNetworkType.getPropertyFromFeature(
                    feature,
                    "label"
                  )}
              </div>
            )
          })}
      </div>
    </div>
  )
  return element
}

function selectAndRenderRouteFeatures(
  this: DrawCustomModeThis,
  state: EditModeState | ExtendExistingModeState,
  feature: NetworkGeometry,
  featuresAt: NetworkGeometry[],
  draw: MapboxDraw
) {
  const featureId = feature.id
  const overlappingFeatures = featuresAt.filter((f) => f.id !== featureId)

  const routeId = state.selectedNetworkType.getPropertyFromFeature(
    feature,
    "routeId"
  )
  if (!routeId) {
    return
  }

  const { featuresOnRoute, featuresNotOnRoute } = getFeaturesByRouteId(
    state.selectedNetworkType,
    draw,
    routeId
  )

  state.isRouteSelected = true
  state.routeFeatureIds = featuresOnRoute.map((f) => f.id!.toString())

  featuresOnRoute.map((f) => {
    this.doRender(f.id!.toString())
  })

  featuresNotOnRoute.map((f) => {
    this.doRender(f.id!.toString())
  })

  const stackItem: SelectStack = {
    mode: "edit_mode",
    operation: "route_selection",
    features: featuresOnRoute.filter((f) => f.geometry.type === "LineString"),
    properties: {
      routeId: routeId,
      overlappingFeatures: overlappingFeatures,
    },
  }
  this.map.fire(MapEvents.ROUTE_SELECTED, {
    stackItem,
  })

  if (state.actionMode === "extend_existing_mode" && "routeId" in state) {
    // this.getEndPoints()
    getAllRouteFeatures(draw)
    state.routeId = routeId
    state.routeFeatures = featuresOnRoute
    state.line = newLine.call(this)
    state.currentVertexPosition = 0
    state.completeLineCoordinates = []
  }
}

function addStop(this: any, state: any, e: any, draw: any): void {
  if (!e.featureTarget) {
    return
  }
  const feature = this.getFeature(e.featureTarget.properties.id)
  if (feature.type !== "LineString") {
    return
  }
  const existingCoordinates = feature.getCoordinates()
  if (!existingCoordinates) {
    return
  }
  const line = lineString(existingCoordinates)
  const cursorAt = point([e.lngLat.lng, e.lngLat.lat])
  const snapped = nearestPointOnLine(line, cursorAt)
  const featureCollection = lineSplit(line, snapped)
  // truncate(lineSplit(line, snapped), {
  //   precision: GEOJSON_COORD_PRECISION,
  // })
  const featuresToAdd: NetworkGeometry[] = []
  const featuresToUpdate: NetworkGeometry[] = []

  const stop = createPointFeature(
    snapped.geometry.coordinates,
    [],
    state.selectedNetworkType
  )
  if (stop) {
    featuresToAdd.push(stop)
    if (state.actionMode === "edit_mode") {
      setTimeout(() => {
        this.changeMode("edit_mode", {
          featureId: stop.id,
          actionMode: state.actionMode,
          selectedNetworkType: state.selectedNetworkType,
        })
      }, 10)
    } else {
      setTimeout(() => {
        state.feature = undefined
        state.featureIds = []
        this.clearSelectedFeatures()
        if (state.actionMode === "extend_existing_mode") {
          state.line = this.newLine()
          state.currentVertexPosition = 0
          state.completeLineCoordinates = []
        }
      }, 10)
    }
  }

  const networkBaseline = state.selectedNetworkType.baselineProperties.Network!

  featureCollection.features.forEach((f, index) => {
    f.properties = JSON.parse(JSON.stringify(feature.properties))
    if (f.properties) {
      const id = f.properties[networkBaseline.id] + "-" + (index + 1)
      f.properties[networkBaseline.id] = id
      // f.properties["has_improvement"] = true
      f.properties["status"] =
        f.properties["status"] === "baseline" ? "updated" : "added"
      f.id = id
    }
    calculateTime(f.geometry, f.properties!)
    featuresToUpdate.push(f as NetworkGeometry)
  })

  draw.add({
    type: "FeatureCollection",
    features: featuresToAdd.concat(featuresToUpdate),
  })

  setTimeout(() => {
    draw.delete(feature.id.toString())
    if (state.selectedNetworkType.name !== "bike" && feature) {
      const filteredFeatures = getAllFeaturesWithSameRouteId(
        state.selectedNetworkType,
        draw,
        feature.properties?.line,
        state.actionMode
      )
      let stackItem: SelectStack = {
        mode: state.actionMode,
        operation: "route_selection",
        features: filteredFeatures,
        properties: { routeId: feature.properties?.line },
      }
      this.map.fire(MapEvents.ROUTE_SELECTED, {
        stackItem,
      })

      this.setSelected(stop?.id as string)
      state.featureIds = [stop?.id as string]
      state.feature = this.getFeature(stop?.id)
      state.featureOrg = state.feature.toGeojson() as NetworkGeometry
      stackItem = {
        mode: state.actionMode,
        operation: "selection_change",
        features: [stop as NetworkGeometry],
        properties: {},
      }
      this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
      if (state.actionMode === "extend_existing_mode") {
        state.line = newLine.call(this)
        state.currentVertexPosition = 0
        state.completeLineCoordinates = []
      }
    }
  }, 10)

  const stackItem: CRUDStack = {
    mode: state.actionMode,
    operation: "added_stop",
    stackState: [
      {
        action: "update",
        oldState: [feature.toGeoJSON() as NetworkGeometry],
        newState: featuresToUpdate,
      },
      {
        action: "create",
        newState: featuresToAdd,
        oldState: [],
      },
    ],
  }

  this.map.fire(MapEvents.CRUD, {
    stackItem,
  })
}

function removePreviewControlPointLayer(map, layerSourceId) {
  if (map.getLayer(layerSourceId)) {
    map.removeLayer(layerSourceId)
  }
  if (map.getSource(layerSourceId)) {
    map.removeSource(layerSourceId)
  }
}

function previewControlPoint(this: any, state: any, e: any) {}

function addControlPoint(this: any, state: any, e: any) {
  if (!e.featureTarget) {
    return
  }
  const feature = this.getFeature(e.featureTarget.properties.id)
  if (feature.type !== "LineString") {
    return
  }
  const existingCoordinates = feature.getCoordinates()
  if (!existingCoordinates) {
    return
  }
  const line = lineString(existingCoordinates)
  const cursorAt = point([e.lngLat.lng, e.lngLat.lat])
  const snapped = nearestPointOnLine(line, cursorAt)
  const featureCollection = lineSplit(line, snapped)
  const featureCoordinates: Position[] = []
  featureCollection.features.forEach((f) => {
    f.geometry.coordinates.forEach((d) => {
      if (!featureCoordinates.includes(d)) {
        featureCoordinates.push(d)
      }
    })
  })
  feature.setCoordinates(featureCoordinates)
  feature.changed()
  state.feature = feature
}

function clickActiveFeature(
  this: DrawCustomModeThis,
  e: MapMouseEvent,
  isShiftClick: boolean,
  state: EditModeState,
  draw: MapboxDraw
) {
  const isRightClick = e.originalEvent.button === 2
  const isLeftClick = e.originalEvent.button === 0
  if (isLeftClick) {
    addControlPoint.call(this, state, e)
    handleSingleFeatureUpdate.call(this, state, draw, {
      action: "update",
      operation: "added_control_point",
    })
  } else if (isRightClick && state.selectedNetworkType.name !== "bike") {
    addStop.call(this, state, e, draw)
  }
}

function clickVertex(
  this: DrawCustomModeThis,
  e: MapMouseEvent,
  isShiftClick: boolean,
  state: EditModeState,
  draw: MapboxDraw
) {
  if (
    e.featureTarget.properties &&
    e.featureTarget.properties.coord_path &&
    e.featureTarget.properties.parent
  ) {
    const feat = this.getFeature(e.featureTarget.properties.parent)
    if (feat.type === "LineString") {
      const coords = feat.getCoordinates()
      const coordPath = parseInt(e.featureTarget.properties.coord_path)
      if (coordPath == 0 || coordPath == coords.length - 1) {
        return
      }
      coords.splice(coordPath, 1)
      feat.setCoordinates(coords)
      feat.changed()
      state.selectedCoordPaths = []
      this.clearSelectedCoordinates()

      handleSingleFeatureUpdate.call(this, state, draw, {
        action: "update",
        operation: "deleted_control_point",
      })
    }
  }
}

function dragVertex(
  this: DrawCustomModeThis,
  state: EditModeState,
  e: MapMouseEvent,
  delta: { lng: number; lat: number }
) {
  if (state.selectedCoordPaths.length === 1) {
    const selected = state.selectedCoordPaths[0]
    const feat = this.getFeature(selected.feature_id)
    if (feat.type === "LineString") {
      feat.updateCoordinate(selected.coord_path, e.lngLat.lng, e.lngLat.lat)
      feat.changed()
    }
  }
}

function dragFeature(
  this: any,
  state: any,
  e: any,
  delta: { lng: number; lat: number },
  draw: MapboxDraw
) {
  state.dragMoveLocation = e.lngLat
}

function startDragging(this: any, state: any, e: mapboxgl.MapMouseEvent) {
  this.map.dragPan.disable()
  state.canDragMove = true
  state.dragMoveLocation = e.lngLat
}

function stopDragging(this: DrawCustomModeThis, state: EditModeState) {
  this.map.dragPan.enable()
  state.dragMoving = false
  state.canDragMove = false
  state.dragMoveLocation = undefined
}

function handleOnVertex(
  this: DrawCustomModeThis,
  state: any,
  e: MapMouseEvent,
  draw: MapboxDraw
) {
  const about = e.featureTarget.properties!
  const selectedIndex = state.selectedCoordPaths.indexOf(about.coord_path)
  if (!CommonSelectors.isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths = [about.coord_path]
  } else if (CommonSelectors.isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths.push(about.coord_path)
  } else if (CommonSelectors.isShiftDown(e) && selectedIndex !== -1) {
    state.selectedCoordPaths.splice(selectedIndex, 1)
  } else {
    return
  }

  const selectedCoordinates = state.selectedCoordPaths.map((coord_path) => ({
    feature_id: about.parent,
    coord_path,
  }))
  this.setSelectedCoordinates(selectedCoordinates)
  this.getFeature(about.parent).changed()
}

function onFeature(this: any, state: any, e: any, draw: MapboxDraw) {
  if (state.selectedCoordPaths.length === 0) startDragging.call(this, state, e)
  else stopDragging.call(this, state)
}

function showRouteSelector(
  this: DrawCustomModeThis,
  state: EditModeState | ExtendExistingModeState,
  e: MapMouseEvent,
  draw: MapboxDraw
) {
  if (isFeature(e) && e.featureTarget && e.featureTarget.properties) {
    const features = this.featuresAt(e as any, [0, 0, 0, 0], "click")
      .filter((f: any) => f.geometry.type === "LineString")
      .map(
        (f) => this.getFeature(f.properties!.id).toGeoJSON() as NetworkGeometry
      )
    let feature = this.getFeature(e.featureTarget.properties.id)
    if (feature.type === "Point") {
      if (features.length == 0) {
        return
      }
      feature = this.getFeature(features[0].id!.toString())
    }
    let routeIds = features.map(
      (f) =>
        state.selectedNetworkType.getPropertyFromFeature(f, "routeId") as string
    )
    routeIds = [...new Set<string>(routeIds)]
    if (routeIds.join(",") !== routesAtPoint.join(",")) {
      routesAtPoint = routeIds
      renderRouteContextMenu.call(this, state, e, features, draw)
    }
  }
}

function higlightFeaturesOnHover(
  this: DrawCustomModeThis,
  state: EditModeState | ExtendExistingModeState,
  e: MapMouseEvent,
  draw: MapboxDraw
) {
  // reset highlighted feature when no target
  if (noTarget(e) || e.featureTarget.properties == undefined) {
    if (state.feature) {
      state.feature.changed()
      state.feature = undefined
    }
    this.updateUIClasses({ mouse: cursors.NONE })
    stopDragging.call(this, state)
    return
  }

  if (isVertex(e)) {
    state.selectedCoordPaths = [
      {
        coord_path: e.featureTarget.properties.coord_path,
        feature_id: e.featureTarget.properties.parent,
      },
    ]
    this.setSelectedCoordinates(state.selectedCoordPaths)
    startDragging.call(this, state, e)
  } else {
    this.clearSelectedCoordinates()
  }

  // Highlight in case of feature
  if (isFeature(e)) {
    // Deselect old highlighted feature
    if (state.feature) {
      if (state.feature.id === e.featureTarget.properties.id) {
        return
      }
      state.feature.changed()
    }

    state.feature = this.getFeature(e.featureTarget.properties.id)
    state.feature.changed()
    findAndSetFeaturesAtPoint.call(this, state, e)

    if (state.feature.type === "Point") {
      startDragging.call(this, state, e)
    } else {
      stopDragging.call(this, state)
    }
  }
}

function insideMouseMove(
  this: DrawCustomModeThis,
  state: EditModeState | ExtendExistingModeState,
  e: MapMouseEvent,
  draw: MapboxDraw,
  isDrawingInprogress?: boolean
) {
  if (state.actionMode === "extend_existing_mode" && "line" in state) {
    let { lng, lat } = snap(state, e)
    // lng = round(lng, GEOJSON_COORD_PRECISION)
    // lat = round(lat, GEOJSON_COORD_PRECISION)
    state.line.updateCoordinate(
      state.currentVertexPosition.toString(),
      lng,
      lat
    )
    state.snappedLng = lng
    state.snappedLat = lat
    stopDragging.call(this, state)
  }
}

function addModeDisplyFeatures(state, geojson, display) {
  const isActiveLine = geojson.properties.id === state.line.id
  geojson.properties.active = isActiveLine
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE
  if (!isActiveLine) return display(geojson)

  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return
  geojson.properties.meta = Constants.meta.FEATURE
  if (state.line.id !== undefined) {
    display(
      createVertex(
        state.line.id.toString(),
        geojson.geometry.coordinates[
          state.direction === "forward"
            ? geojson.geometry.coordinates.length - 2
            : 1
        ],
        `${
          state.direction === "forward"
            ? geojson.geometry.coordinates.length - 2
            : 1
        }`,
        false
      )
    )
  }
  display(geojson)
}

function editModeDisplayFeatures(state: EditModeState, geojson, display) {
  // Hover effect
  // if hovered then set property hovered to true - would be triggered by .changed()
  // if selected then set property active to true - would be triggered by .select()
  if (state.feature?.id === geojson.properties.id) {
    geojson.properties.hovered = "true"
  } else {
    geojson.properties.hovered = "false"
  }

  // Route selection
  if (state.isRouteSelected) {
    if (state.routeFeatureIds.includes(geojson.properties.id)) {
      geojson.properties.route_mode = "true"
    } else {
      geojson.properties.route_mode = "false"
    }
  }

  // Select feature
  if (
    getSelectedFeaturesIds(state.selectedFeatures).includes(
      geojson.properties.id
    )
  ) {
    geojson.properties.active = Constants.activeStates.ACTIVE
    createSupplementaryPoints(geojson, {
      midpoints: false,
      selectedPaths: state.selectedCoordPaths
        .filter((f) => f.feature_id === geojson.properties.id)
        .map((f) => f.coord_path),
    }).forEach((p) => {
      display(p)
    })
  } else {
    geojson.properties.active = Constants.activeStates.INACTIVE
  }

  display(geojson)
}

function onContextMenuClick(this: any, state: any, e: any) {
  this.clearSelectedFeatures()
  const featureId = e.target.id
  state.featureIds = [featureId]
  this.select(featureId)
  if (state.actionMode == "extend_existing_mode") {
    const stackItem: SelectStack = {
      mode: "extend_existing_mode",
      operation: "selection_change",
      features: this.getSelected().map((d) => d.toGeoJSON() as NetworkGeometry),
      properties: {},
    }

    this.map.fire(MapEvents.SELECTION_CHANGE, { stackItem })
  } else {
    this.changeMode("edit_mode", {
      featureId: featureId,
      actionMode: state.actionMode,
      selectedNetworkType: state.selectedNetworkType,
    })
  }
  popup.remove()
}

function newLine(this: DrawCustomModeThis): MapboxDraw.DrawLineString {
  const line = this.newFeature({
    type: geojsonTypes.FEATURE,
    properties: { title: "Incomplete" },
    geometry: {
      type: geojsonTypes.LINE_STRING,
      coordinates: [[]],
    },
  }) as DrawLineString

  this.addFeature(line)
  return line
}

function moveOverlappingFeatures(
  this: DrawCustomModeThis,
  state: EditModeState,
  draw: MapboxDraw
) {
  if (
    state.featuresAtPoint &&
    state.featuresAtPoint.length > 0 &&
    state.dragMoveLocation != undefined
  ) {
    state.featuresAtPoint.forEach((featureAtPoint: FeaturesAtPoint) => {
      const drawFeature = this.getFeature(featureAtPoint.feature.id!.toString())
      if (drawFeature.type === "LineString") {
        drawFeature.updateCoordinate(
          featureAtPoint.coordIdx.toString(),
          state.dragMoveLocation!.lng,
          state.dragMoveLocation!.lat
        )
      } else if (drawFeature.type === "Point") {
        drawFeature.setCoordinates([
          state.dragMoveLocation!.lng,
          state.dragMoveLocation!.lat,
        ])
      }
      drawFeature.changed()
    })
  }
}

function findAndSetFeaturesAtPoint(
  this: DrawCustomModeThis,
  state: EditModeState,
  e: MapMouseEvent
) {
  if (state.feature) {
    let coords: Position | undefined = undefined
    if (state.feature.type === "Point") {
      coords = state.feature.getCoordinates()
    } else if (state.feature.type === "LineString") {
      const pointOnLine = nearestPointOnLine(
        state.feature,
        point([e.lngLat.lng, e.lngLat.lat])
      )
      coords = state.feature.getCoordinates()[pointOnLine.properties.index]
    }
    if (coords) {
      const latLng = this.map.project({ lat: coords[1], lng: coords[0] })
      const allFeatures = this.featuresAt(
        undefined as any,
        [latLng.x, latLng.y, latLng.x, latLng.y],
        "click"
      )
      const features = allFeatures.filter((f) => {
        return !(
          f.properties &&
          f.properties.meta &&
          f.properties.meta === "vertex"
        )
      })
      if (features.length > 0) {
        // Find overlapping features
        state.featuresAtPoint = features
          .map(
            (f) =>
              this.getFeature(f.properties!.id).toGeoJSON() as NetworkGeometry
          )
          .map((f) => {
            let loc = 0
            if (f.geometry.type === "LineString") {
              let coordinates: Position[] = f.geometry.coordinates
              for (let i = 0; i < coordinates.length; i++) {
                if (booleanEqual(point(coords), point(coordinates[i]))) {
                  loc = i
                  break
                }
              }
            }
            return {
              feature: f,
              coordIdx: loc,
            } as FeaturesAtPoint
          })
      }
    }
  }
}

function clearSelection(this: DrawCustomModeThis, state: EditModeState) {
  state.feature = undefined
  state.featureOrg = {}
  state.featuresAtPoint = []
  state.selectedFeatures = []
  state.selectedCoordPaths = []
  this.clearSelectedFeatures()
  this.clearSelectedCoordinates()
}

function getSelectedFeaturesIds(features: DrawFeature[]) {
  return features.map((f) => f.id as string)
}

function clickInactive(
  this: DrawCustomModeThis,
  state: EditModeState,
  e: MapMouseEvent,
  draw: MapboxDraw,
  isShiftClick: boolean
) {
  if (e.featureTarget == undefined || e.featureTarget.properties == undefined) {
    return
  }
  const featureId = e.featureTarget.properties.id

  let feature = this.getFeature(featureId)
  // Route selection
  if (!state.isRouteSelected && state.selectedNetworkType.name !== "bike") {
    if (feature.type === "Point") {
      const features = this.featuresAt(e as any, [0, 0, 0, 0], "click").filter(
        (f: any) => f.geometry.type === "LineString"
      )
      if (features.length == 0) {
        return
      }
      feature = this.getFeature(features[0].properties!.id.toString())
    }
    //displayRouteSelector.call(this, state, e, feature, draw)
    return
  } else if (state.isRouteSelected) {
    state.feature = feature
    state.feature.changed()
    state.selectedFeatures.push(feature)
    state.featureOrg[featureId] = feature.toGeoJSON() as NetworkGeometry
    this.setSelected(getSelectedFeaturesIds(state.selectedFeatures))
  }
}

function clickNoTarget(
  this: DrawCustomModeThis,
  state: EditModeState,
  e: MapMouseEvent,
  isShiftClick: boolean
) {
  if (isShiftClick) {
    return
  }

  if (state.actionMode === "add_mode") {
    popup.remove()
    this.changeMode("add_mode", {
      selectedNetworkType: state.selectedNetworkType,
      init: false,
    })
    const stackItem: SelectStack = {
      mode: "add_mode",
      operation: "route_selection",
      features: [],
      properties: {},
    }
    this.map.fire(MapEvents.ROUTE_SELECTED, {
      stackItem,
    })
  } else {
    clearSelection.call(this, state)
    popup.remove()
  }
}

function handleDraw(
  this: DrawCustomModeThis,
  state: EditModeState,
  draw: MapboxDraw
) {
  if (state.dragMoving) {
    const oldState: NetworkGeometry[] = []
    state.featuresAtPoint.forEach((featureAtPoint) => {
      oldState.push(clone(featureAtPoint.feature))
    })

    const newState = state.featuresAtPoint.map((featureAtPoint) => {
      const drawFeature = this.getFeature(featureAtPoint.feature.id!.toString())
      if (drawFeature.properties) {
        const status =
          drawFeature.properties.status === "baseline" ? "updated" : "added"
        drawFeature.setProperty("status", status)
      }
      drawFeature.changed()
      return drawFeature.toGeoJSON() as NetworkGeometry
    })

    fireUpdate.call(this, draw, oldState, newState, {
      action: "update",
      operation: "updated_coordinates",
      mode: "edit_mode",
    })
    stopDragging.call(this, state)
  }
}

function handleSingleFeatureUpdate(
  this: DrawCustomModeThis,
  state: EditModeState,
  draw: MapboxDraw,
  stackInfo: {
    action: StackAction
    operation: CRUDOperation
  }
) {
  if (
    state.feature &&
    state.featureOrg &&
    state.featureOrg[state.feature.id!.toString()]
  ) {
    const oldState = [state.featureOrg[state.feature.id!.toString()]]
    const newState = [
      this.getFeature(
        state.feature.id!.toString()
      ).toGeoJSON() as NetworkGeometry,
    ]

    fireUpdate.call(this, draw, oldState, newState, {
      action: stackInfo.action,
      operation: stackInfo.operation,
      mode: "edit_mode",
    })
  }
}

function fireUpdate(
  this: DrawCustomModeThis,
  draw: MapboxDraw,
  oldState: NetworkGeometry[],
  newState: NetworkGeometry[],
  stackInfo: {
    action: StackAction
    operation: CRUDOperation
    mode: OperationMode
  }
) {
  const stackItem: CRUDStack = {
    stackState: [
      {
        action: stackInfo.action,
        oldState: oldState,
        newState: newState,
      },
    ],
    operation: stackInfo.operation,
    mode: stackInfo.mode,
  }

  draw.add({
    type: "FeatureCollection",
    features: newState,
  })

  this.map.fire(MapEvents.CRUD, {
    stackItem,
  })
}

function isNonRouteFeature(state: EditModeState, e: MapMouseEvent) {
  return (
    (isFeature(e) || isVertex(e)) &&
    state.isRouteSelected &&
    e.featureTarget?.properties?.route_mode === "false"
  )
}

export {
  renderRouteContextMenu,
  getContextMenuForFeatures,
  addStop,
  addControlPoint,
  clickActiveFeature,
  dragVertex,
  dragFeature,
  handleOnVertex,
  onFeature,
  startDragging,
  stopDragging,
  insideMouseMove,
  addModeDisplyFeatures,
  editModeDisplayFeatures,
  onContextMenuClick,
  newLine,
  moveOverlappingFeatures,
  clearSelection,
  clickInactive,
  clickNoTarget,
  handleDraw,
  fireUpdate,
  showRouteSelector,
  findAndSetFeaturesAtPoint,
  higlightFeaturesOnHover,
  isNonRouteFeature,
  clickVertex,
  handleSingleFeatureUpdate,
}
