import * as React from "react"
import { ChangeEvent } from "react"
import {
  Baseline,
  BaselineType,
  GeoJsonProperties,
  NetworkGeometry,
  NetworkType,
  Property,
  PropertyLevel,
} from "../../specs/Networks"
import intl from "react-intl-universal"
import * as turf from "@turf/turf"
import { clone, round } from "@turf/turf"
import Tippy from "@tippyjs/react"
import { updatedDiff } from "deep-object-diff"
import {
  PropertyEditorCallbackData,
  PropertyEditorCallbackType,
} from "../../specs/Views"
import InputRange from "react-input-range"
import { OperationMode, StackState } from "../../specs/Mapbox"
import { LineString, Position } from "geojson"
import { calculateSpeed, calculateTime } from "../../utils/mapbox/FeaturesUtils"
import average from "average"
import { cloneDeep } from "lodash"

const levels: PropertyLevel[] = ["route", "segment"]
export type EditorState = "guide" | "single" | "bulk" | "route"

interface ControlButton {
  localeKey: string
  onClick: () => void
  className: string
  iconClassName: string
  condition: () => boolean
}

interface SideActionWindowProps {
  selectedNetworkType: NetworkType
  currentSelection: NetworkGeometry[]
  operationMode: OperationMode
  selectedRoute: string
  callback: (
    type: PropertyEditorCallbackType,
    data: PropertyEditorCallbackData
  ) => void
  isHelpBtnClicked: boolean
  isRouteLevelSelection: boolean
  draw: MapboxDraw
  routeFeatures: NetworkGeometry[]
}

interface SideActionWindowState {
  properties: GeoJsonProperties
  orgProperties: GeoJsonProperties
  stopDistances: { [key in string]: number }
  displayMode: "view_property" | "edit_property" | "autogenerate_stops"
  isPanelHidden: boolean
  editorState: EditorState
  baseline: Baseline
  baselineType: BaselineType
  selectedGeometryType: SelectedGeometryTypeState
  updateBtnDisabled: boolean
  tabIndex: number
  averageSpeed: number
  orgRouteFeatures: any
  multipleRouteSelected: boolean
  formError: string
  lineDistance: number
}

const inputType = {
  boolean: "checkbox",
  number: "number",
  string: "text",
}

let getAverageSpeed = false
type SelectedGeometryTypeState =
  | "onlyPointType"
  | "onlyLineType"
  | "bothType"
  | ""

export default class SideActionWindowView extends React.Component<
  SideActionWindowProps,
  SideActionWindowState
> {
  controlButtons: ControlButton[] = [
    {
      localeKey: "editor.control.edit",
      onClick: () => this.toggleEditing(false),
      className: "editDetailsButton",
      iconClassName: "fa-pen",
      condition: () =>
        this.state.selectedGeometryType !== "bothType" &&
        !this.state.multipleRouteSelected,
    },
    {
      localeKey: "editor.control.autogenerate_stops",
      onClick: () => this.toggleAutogenerateStop(),
      className: "autogenerateStopsButton",
      iconClassName: "fa-location-dot",
      condition: () =>
        this.state.baselineType === "Network" &&
        (this.props.selectedNetworkType.name === "tram" ||
          this.props.selectedNetworkType.name === "bus") &&
        (this.props.operationMode == "add_mode" ||
          (this.props.operationMode == "extend_existing_mode" &&
            this.state.editorState === "single")) &&
        this.state.selectedGeometryType !== "bothType" &&
        !this.state.multipleRouteSelected,
    },
    {
      localeKey: "editor.control.combineFeatures",
      onClick: () => this.handleCombineFeatures(),
      className: "editDetailsButton",
      iconClassName: "fa-object-group",
      condition: () =>
        this.props.draw.getSelectedIds().length > 1 &&
        this.state.selectedGeometryType === "onlyLineType",
    },
    {
      localeKey: "editor.control.combineRoutes",
      onClick: () => this.handleCombineRoutes(),
      className: "editDetailsButton",
      iconClassName: "fa-link",
      condition: () => this.state.multipleRouteSelected,
    },
    {
      localeKey: "editor.control.delete",
      onClick: () => this.handleDelete(),
      className: "deleteButton",
      iconClassName: "fa-trash-can",
      condition: () =>
        this.props.operationMode === "extend_existing_mode" &&
        this.state.editorState === "route"
          ? false
          : true,
    },
  ]

  constructor(props: SideActionWindowProps) {
    super(props)
    this.state = {
      displayMode: "view_property",
      stopDistances: {},
      properties: {},
      orgProperties: {},
      isPanelHidden: false,
      baseline: new Baseline("", "", "", "", []),
      editorState: "guide",
      baselineType: "Network",
      selectedGeometryType: "",
      updateBtnDisabled: true,
      tabIndex: 0,
      averageSpeed: 0,
      orgRouteFeatures: [],
      multipleRouteSelected: false,
      formError: "",
      lineDistance: 0,
    }

    this.toggleEditing = this.toggleEditing.bind(this)
    this.toggleAutogenerateStop = this.toggleAutogenerateStop.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.slideInOutDiv = this.slideInOutDiv.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<SideActionWindowProps>) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState(this.getCurrentState())
    }

    const propertySection = document.querySelector(".property-section")
    const guidesSection = document.querySelector(".guides-section")

    if (guidesSection) {
      const propertySectionHeight = (propertySection as HTMLElement) ? (propertySection as HTMLElement)
        .scrollHeight : 0
      const windowHeight = window.innerHeight - 100

      const guidesSectionHeight = (guidesSection as HTMLElement).scrollHeight
      const availableHeight = windowHeight - propertySectionHeight

      if (guidesSectionHeight > availableHeight) {
        ;(guidesSection as HTMLElement).style.maxHeight = `${availableHeight}px`
        ;(guidesSection as HTMLElement).style.overflowY = "auto"
      } else {
        ;(guidesSection as HTMLElement).style.maxHeight = `${availableHeight}px`
        ;(guidesSection as HTMLElement).style.overflowY = "hidden"
      }
    }
  }

  getCurrentState(): any {
    let properties: GeoJsonProperties = {}
    let orgProperties: GeoJsonProperties = {}
    let orgRouteFeatures: any = []
    let editorState: EditorState = "guide"
    let selectedGeometryType: SelectedGeometryTypeState = ""
    let baseline: Baseline =
      this.props.selectedNetworkType.baselineProperties.Network!
    let baselineType: BaselineType = "Network"
    let tabIndex: number = 0
    const displayMode = "view_property"
    let multipleRouteSelected = false
    let lineDistance: number = 0
    if (
      this.props.currentSelection.length !== 0 &&
      !this.props.isHelpBtnClicked
    ) {
      baselineType =
        this.props.currentSelection[0].geometry.type === "LineString"
          ? "Network"
          : "Stops"
      baseline =
        this.props.selectedNetworkType.baselineProperties[baselineType]!
      if (this.props.currentSelection.length == 1) {
        editorState = "single"
        properties = this.props.currentSelection[0].properties
      } else if (this.props.currentSelection.length > 1) {
        if (this.props.isRouteLevelSelection) {
          editorState = "route"
          properties = this.props.currentSelection[0].properties
        } else {
          editorState = "bulk"
          properties = this.props.currentSelection[0].properties
        }
      }

      if (this.props.currentSelection.length > 0) {
        tabIndex = 1
      }

      if (editorState === "route" || editorState === "bulk") {
        this.calculateAverageSpeed()
      } else if (
        !properties["speed"] &&
        properties["time_min"] &&
        this.props.selectedNetworkType.name !== "bike"
      ) {
        calculateSpeed(this.props.currentSelection[0].geometry, properties)
      } else if (!properties["time_min"] && properties["speed"]) {
        calculateTime(this.props.currentSelection[0].geometry, properties)
      }

      if (!properties["time_min"] && properties["speed_kmhr"]) {
        calculateTime(
          this.props.currentSelection[0].geometry,
          properties,
          "speed_kmhr"
        )
      }

      baseline.properties.forEach((prop) => {
        if (properties["status"] !== "baseline") {
          return
        }

        if (
          properties[prop.propertyName] == "" ||
          properties[prop.propertyName] == undefined
        ) {
          if (prop.defaultValue) {
            properties[prop.propertyName] = prop.defaultValue(
              this.props.currentSelection[0].geometry,
              prop.propertyName,
              this.props.selectedNetworkType
            )
          }
        }
      })

      this.props.draw.add({
        type: "FeatureCollection",
        features: this.props.currentSelection,
      })

      orgProperties = JSON.parse(JSON.stringify(properties))
      orgRouteFeatures = this.props.routeFeatures

      const geometryTypes = new Set<string>()
      const multipleRoutes = new Set<string>()
      this.props.currentSelection.forEach((feature) => {
        if (feature.geometry.type == undefined) {
          return
        }
        geometryTypes.add(feature.geometry.type)
        if (
          feature.geometry.type === "LineString" &&
          feature.properties.line !== undefined
        ) {
          multipleRoutes.add(feature.properties.line)
        }
      })

      const hasPoint = geometryTypes.has("Point")
      const hasLineString = geometryTypes.has("LineString")
      if (multipleRoutes.size > 1) {
        multipleRouteSelected = true
      } else if (hasPoint && !hasLineString) {
        selectedGeometryType = "onlyPointType"
      } else if (!hasPoint && hasLineString) {
        selectedGeometryType = "onlyLineType"
      } else if (geometryTypes.size > 1) {
        selectedGeometryType = "bothType"
      }

      if (hasLineString && !multipleRouteSelected) {
        const lineString = turf.lineString(
          this.props.currentSelection.filter(
            (feature) => feature.geometry.type === "LineString"
          )[0].geometry.coordinates as Position[]
        )
        lineDistance = turf.length(lineString, { units: "meters" })
      }
    }
    return {
      editorState,
      baseline,
      properties,
      baselineType,
      orgProperties,
      selectedGeometryType,
      tabIndex,
      displayMode,
      orgRouteFeatures,
      multipleRouteSelected,
      lineDistance,
    }
  }

  calculateAverageSpeed() {
    const speed: number[] = []
    this.props.currentSelection
      .filter((f) => f.geometry.type === "LineString")
      .forEach((feature) => {
        if (
          !feature.properties["speed"] &&
          feature.properties["time_min"] &&
          this.props.selectedNetworkType.name !== "bike"
        ) {
          const calculatedSpeed = calculateSpeed(
            feature.geometry,
            feature.properties
          )
          if (calculatedSpeed) {
            speed.push(calculatedSpeed)
          }
        } else {
          speed.push(feature.properties["speed"])
        }
      })
    if (speed.length) {
      const result = average(speed)
      this.setState({
        averageSpeed: round(result, 1),
      })
      getAverageSpeed = true
    }
  }

  saveChanges() {
    const state: any = {}
    let oldState: NetworkGeometry[] = []
    const newState: NetworkGeometry[] = []
    if (this.state.displayMode === "edit_property") {
      // Update at route level
      // Check if route level required
      // Form diff for route and edge
      // Route Level: If mode = 'route', mode = 'bulk'/'edge' and property is route level
      // Bulk Level: If mode = 'bulk' and property is edge level
      // Edge Level: If mode = 'single' and property is edge level
      const diff = updatedDiff(this.state.orgProperties, this.state.properties)

      // UPDATE ROUTE LEVEL PROPERTIES
      const routeLevelProps = this.state.baseline.properties.filter(
        (property) =>
          Object.keys(diff).includes(property.propertyName) &&
          (property.level === "route" || this.props.isRouteLevelSelection)
      )
      const routeLevelDiff: GeoJsonProperties = {}
      routeLevelProps.forEach(
        (property) =>
          (routeLevelDiff[property.propertyName] = diff[property.propertyName])
      )
      if (routeLevelProps.length > 0) {
        new Set<string>(
          this.props.currentSelection.map((f) =>
            this.props.selectedNetworkType.getPropertyFromFeature(f, "routeId")
          )
        ).forEach((routeId) => {
          this.props.draw
            .getAll()
            .features.map((f) => f as NetworkGeometry)
            .filter(
              (feature) =>
                this.props.selectedNetworkType.getPropertyFromFeature(
                  feature,
                  "routeId"
                ) === routeId
            )
            .forEach((feature) => {
              feature.properties = Object.assign(
                feature.properties,
                routeLevelDiff
              )
              this.doOnChange(routeLevelProps, routeLevelDiff, feature)
              // feature.properties["has_improvement"] = true
              feature.properties["status"] =
                feature.properties["status"] === "baseline"
                  ? "updated"
                  : "added"
              newState.push(feature)
            })
          oldState = cloneDeep(this.state.orgRouteFeatures)
        })
      }

      // UPDATE EDGE LEVEL PROPERTIES
      const edgeLevelProps = this.state.baseline.properties
        .filter((p) => !routeLevelProps.includes(p))
        .filter(
          (property) =>
            Object.keys(diff).includes(property.propertyName) &&
            property.level === "segment"
        )
      const edgeLevelDiff: GeoJsonProperties = {}
      edgeLevelProps.forEach(
        (property) =>
          (edgeLevelDiff[property.propertyName] = diff[property.propertyName])
      )

      if (edgeLevelProps.length > 0) {
        this.props.currentSelection.forEach((feature) => {
          const existing = newState.find((f) => f.id === feature.id)
          if (existing) {
            feature = existing
          } else {
            const feat: NetworkGeometry = clone(feature)
            feat.properties = this.state.orgProperties
            oldState.push(feat)
          }
          feature.properties = Object.assign(feature.properties, edgeLevelDiff)
          this.doOnChange(
            edgeLevelProps,
            edgeLevelDiff,
            feature as NetworkGeometry
          )
          if (!existing) {
            // feature.properties["has_improvement"] = true
            feature.properties["status"] =
              feature.properties["status"] === "baseline" ? "updated" : "added"
            newState.push(feature)
          }
        })
      }

      const stackItem: StackState = {
        action: "update",
        oldState: oldState,
        newState: newState,
        oldSelection: cloneDeep(this.state.orgRouteFeatures),
        newSelection: this.state.orgRouteFeatures,
      }
      this.props.callback("update", { data: { stackItem } })
      state.orgProperties = { ...this.state.properties }
    } else if (this.state.displayMode === "autogenerate_stops") {
      this.props.callback("autogenerated_stop", {
        mode: "save",
      })
    }
    this.setState(state, () => {
      this.toggleEditing(false)
    })

    if (
      this.state.editorState === "route" ||
      this.state.editorState === "bulk"
    ) {
      this.calculateAverageSpeed()
    }
  }

  doOnChange(
    properties: Property[],
    diff: GeoJsonProperties,
    feature: NetworkGeometry
  ) {
    if (
      Object.prototype.hasOwnProperty.call(diff, "speed") &&
      feature.geometry.type === "LineString"
    ) {
      const speedProp = properties.find(
        (prop) => prop.propertyName === "speed"
      )!
      speedProp.onChange!(feature.geometry as LineString, feature.properties)
    }
  }

  getContent() {
    return (
      <>
        {this.props.currentSelection.length > 0 ? (
          <div className="property-section">
            <div className="section-header">
              {intl.get("editor.property_editor")}
            </div>
            <div className="property-header mt-2">{this.renderHeader()}</div>
            <div className="property-subheader">{this.renderSubHeader()}</div>
            {/* <hr className="divider" /> */}
            <div className="property-body">
              {this.renderPropertyBody()}
              {this.state.formError &&
              this.state.displayMode === "edit_property" ? (
                <p className="text-xs text-red-500">{this.state.formError}</p>
              ) : null}
              <div className="property-form-button">
                {this.renderFormButtons()}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.currentSelection.length > 0 ? (
          <hr className="divider" />
        ) : null}
        <div className="guides-section">
          <div className="section-header">{intl.get("button.guides")}</div>
          <div>{this.guides()}</div>
        </div>
      </>
    )
  }

  renderPropertyBody() {
    if (
      this.state.displayMode == "view_property" ||
      this.state.displayMode == "edit_property"
    ) {
      return this.state.editorState === "single"
        ? levels.map((level) => this.renderPropertiesForLevel(level))
        : this.renderPropertiesForLevel("route")
    }

    if (this.state.displayMode == "autogenerate_stops") {
      return this.renderAutogenerateStopForm()
    }
  }

  handleCancel() {
    if (this.state.displayMode === "edit_property") {
      this.toggleEditing(true)
      this.setState({
        formError: "",
      })
    } else if (this.state.displayMode === "autogenerate_stops") {
      this.props.callback("autogenerated_stop", {
        mode: "clear",
      })
      this.setState({
        displayMode: "view_property",
      })
    }
  }

  renderHeader() {
    const {
      properties,
      baseline: { label, routeId },
      editorState,
      selectedGeometryType,
      multipleRouteSelected,
    } = this.state
    const { selectedNetworkType } = this.props

    let title = properties[label]

    if (
      (selectedNetworkType.name === "tram" &&
        this.props.operationMode === "edit_mode") ||
      this.props.operationMode === "extend_existing_mode"
    ) {
      const route = properties[routeId]
      title = route
        ? `[${route.toUpperCase().replace("_", "-")}] ${title}`
        : editorState === "bulk" || editorState === "route"
          ? intl.get("editor.multi_stop_selected")
          : title
    }
    if (selectedNetworkType.name === "bike") {
      if (label == "category" && title) {
        selectedNetworkType.baselineProperties.Network?.properties.forEach(
          (property) => {
            if (property.propertyName === label) {
              property.options?.forEach((option) => {
                if (option.label === title) {
                  title = intl.get(option.localeKey)
                }
              })
            }
          }
        )
      }
    }

    if (
      this.props.operationMode === "add_mode" &&
      selectedGeometryType === "onlyPointType" &&
      this.props.currentSelection.length > 1
    ) {
      title = intl.get("editor.multi_stop_selected")
    }

    if (
      (editorState === "bulk" || editorState === "route") &&
      selectedGeometryType === "bothType"
    ) {
      title = intl.get("editor.multi_geometry_selected")
    }

    if (multipleRouteSelected) {
      title = intl.get("editor.multi_routes_selected")
    }

    const featureTitle =
      title ||
      intl.get(
        `newly_added_${selectedNetworkType.name}_${
          this.state.baselineType === "Stops" ? "stop" : "path"
        }`
      )

    const isNetworkBaseline = this.state.baselineType === "Network"
    const iconClass = isNetworkBaseline
      ? "fa-solid fa-road"
      : "fa-solid fa-circle"

    return (
      <div className="featureName">
        {selectedGeometryType !== "bothType"
          ? iconClass && <i className={iconClass}></i>
          : null}

        <span title={featureTitle}>{featureTitle}</span>
      </div>
    )
  }

  renderSubHeader() {
    const { currentSelection, selectedNetworkType } = this.props
    let ele: React.JSX.Element = <></>
    if (
      this.state.displayMode === "edit_property" &&
      currentSelection[0].geometry.type === "LineString" &&
      selectedNetworkType.name !== "bike"
    ) {
      ele = (
        <div
          className="noteDiv"
          style={{
            marginTop: "10px",
            color: "#A5A5A5",
            fontSize: "12px",
            lineHeight: "normal",
          }}
        >
          <b> {intl.get("editor.note")} :</b>{" "}
          {intl.get("editor.edit_properties_note")}
        </div>
      )
    } else if (this.state.displayMode === "view_property") {
      ele = (
        <div className="actionButtons">
          {this.controlButtons
            .filter((ele) => ele.condition())
            .map((ele) => (
              <Tippy
                key={intl.get(ele.localeKey)}
                content={intl.get(ele.localeKey)}
                placement={"bottom"}
                trigger={"mouseenter"}
              >
                <button
                  onClick={ele.onClick}
                  className={`actionButton ${ele.className}`}
                >
                  {" "}
                  <span className="iconSpan">
                    {" "}
                    <i className={`fa-solid ${ele.iconClassName}`}></i>
                  </span>{" "}
                </button>
              </Tippy>
            ))}
        </div>
      )
    }
    return <>{ele}</>
  }

  renderPropertiesForLevel(level: PropertyLevel) {
    const { currentSelection, selectedNetworkType } = this.props
    const { baseline, editorState, properties, selectedGeometryType } =
      this.state

    const networkProperties =
      baseline.properties.filter(
        (property) =>
          !property.disabled &&
          ((editorState === "single" && property.level === level) ||
            ((editorState === "bulk" || editorState === "route") &&
              property.bulk))
      ) || []

    if (
      (editorState === "bulk" || editorState === "route") &&
      selectedGeometryType === "bothType"
    ) {
      return <p>{intl.get("editor.bulk_edit_multiple_geometry_type_msg")}</p>
    }
    if (this.state.multipleRouteSelected) {
      return <p>{intl.get("editor.multi_routes_selected")}</p>
    }

    if (networkProperties.length === 0) {
      return null
    }

    return (
      <div key={level}>
        {currentSelection[0].geometry.type !== "Point" &&
        selectedNetworkType.name !== "bike" ? (
          <p className="property-level">
            {intl.get(`properties.level.${level}_attributes`)}
          </p>
        ) : null}
        {networkProperties.map((property) => {
          const propertyValue = properties[property.propertyName]
          return (
            <div key={property.propertyName} className="key-value-pair">
              <div className="key">
                {intl.get(`properties.${property.localeKey}`)}:
              </div>
              <div className="value">
                {this.renderValueBasedOnMode(property, propertyValue)}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  renderValueBasedOnMode(property: Property, propertyValue: any) {
    if (this.state.displayMode === "edit_property") {
      let ele: React.JSX.Element
      if (
        property.datatype === "dropdown" &&
        property.options &&
        property.options.length > 0
      ) {
        ele = (
          <select
            name={property.propertyName}
            value={propertyValue}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              this.handleInputChange(property, e)
            }
          >
            <option value="" disabled>
              {intl.get("editor.select")}
            </option>
            {property.options?.map((option) => (
              <option key={option.value} value={option.value}>
                {intl.get(option.localeKey) != ""
                  ? intl.get(option.localeKey)
                  : option.label}
              </option>
            ))}
          </select>
        )
      } else {
        ele = (
          <input
            name={property.propertyName}
            type={inputType[property.datatype]}
            value={this.displayValue(property, propertyValue)}
            title={intl.get("properties." + property.localeKey)}
            checked={property.datatype === "boolean" ? propertyValue : false}
            placeholder={intl.get("properties." + property.localeKey)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              this.handleInputChange(property, e)
            }
            min={1}
            disabled={property.readonly}
          />
        )
      }
      return ele
    }
    return this.displayValue(property, propertyValue)
  }

  renderFormButtons() {
    if (this.state.displayMode === "view_property") {
      return <></>
    }
    let ele: React.JSX.Element = <></>
    if (this.state.displayMode === "edit_property") {
      ele = (
        <button
          className="submitModalButton"
          onClick={this.saveChanges}
          disabled={this.state.updateBtnDisabled || this.state.formError != ""}
        >
          {intl.get("button.update")}
        </button>
      )
    }
    if (this.state.displayMode === "autogenerate_stops") {
      ele = (
        <button className="submitModalButton" onClick={this.saveChanges}>
          {intl.get("button.add")}
        </button>
      )
    }
    return (
      <div className="action-buttons">
        <button className="cancelModalButton" onClick={this.handleCancel}>
          {intl.get("button.cancel")}
        </button>
        {ele}
      </div>
    )
  }

  getStopDistance(): number {
    const distances = Object.keys(this.state.stopDistances).filter((id) =>
      this.props.currentSelection.some((d) => d.id === id)
    )
    return distances.length > 0 ? this.state.stopDistances[distances[0]] : 500
  }

  renderAutogenerateStopForm() {
    return (
      <div className="autogenerateStopsForm">
        {" "}
        <p className="distance-modal-title">{intl.get("editor.add_stops")}</p>
        <p className="distance-modal-info">
          {intl.get("stop.at_distance_of", {
            distance: this.getStopDistance() ? this.getStopDistance() : 500,
          })}
        </p>
        <InputRange
          step={100}
          maxValue={this.state.lineDistance}
          minValue={500}
          formatLabel={(value) => `${value} m`}
          value={this.getStopDistance()}
          onChange={(value) => {
            if (typeof value === "number") {
              const stopDistances = this.state.stopDistances
              this.props.currentSelection.forEach((f: any) => {
                stopDistances[f.id] = value
              })
              this.setState({ stopDistances }, () => {
                this.props.callback("autogenerated_stop", {
                  mode: "preview",
                  data: { distance: value },
                })
              })
            }
          }}
        />
      </div>
    )
  }

  displayValue(property: Property, propertyValue: string) {
    if (property.localeKey === "speed") {
      // Check if the editor state is "route" or "bulk"
      if (
        (this.state.editorState === "route" ||
          this.state.editorState === "bulk") &&
        getAverageSpeed
      ) {
        // Return the actual property value without modifying it
        return this.state.averageSpeed
      }
    }

    if (property.datatype === "dropdown") {
      const data = property.options?.find((d) => d.value === propertyValue)
      if (data) {
        const localeData = intl.get(data.localeKey)
        if (localeData != "") {
          return localeData
        }
      }
    }
    if (property.datatype === "number" && property.precision && propertyValue) {
      if (isNaN(parseFloat(propertyValue))) {
        return propertyValue
      }
      return round(parseFloat(propertyValue), property.precision)
    }
    if (property.datatype === "boolean") {
      return propertyValue ? "True" : "False"
    }
    return propertyValue
  }

  guides() {
    return (
      <div
        className="content"
        style={{
          textAlign:
            intl.getInitOptions().currentLocale === "nl" ? "left" : "justify",
        }}
      >
        {this.props.selectedNetworkType.name === "bike" &&
        this.props.operationMode === "edit_mode"
          ? intl.getHTML(`editor.guides.edit_mode_bike`)
          : intl.getHTML(`editor.guides.${this.props.operationMode}`)}
      </div>
    )
  }

  handleInputChange(property: Property, e: any) {
    if (property.localeKey === "speed") {
      getAverageSpeed = false
    }
    const { properties } = this.state
    const value = e.target.value
    let parsedValue: any = value
    let updateBtnDisabled: boolean = true
    if (property.datatype === "number" && value.length > 0) {
      parsedValue = property.precision
        ? round(parseFloat(value), property.precision)
        : parseFloat(value)
    } else if (property.datatype === "boolean") {
      parsedValue = e.target.checked
    }

    const updatedProperties = {
      ...properties,
      [property.propertyName]: parsedValue,
    }

    if (this.state.editorState === "single") {
      if (property.onChange) {
        property.onChange(
          this.props.currentSelection[0].geometry,
          updatedProperties
        )
      }
    }

    if (this.props.selectedNetworkType.name !== "bike") {
      if (
        updatedProperties["time_min"] <= 0 ||
        updatedProperties["time_min"] == ""
      ) {
        this.setState({ formError: intl.get("error.invalid_time") })
      } else if (
        updatedProperties["speed"] <= 0.1 ||
        updatedProperties["speed"] == ""
      ) {
        this.setState({ formError: intl.get("error.invalid_speed") })
      } else if (
        updatedProperties["frequency_min"] <= 0 ||
        updatedProperties["frequency_min"] == ""
      ) {
        this.setState({ formError: intl.get("error.invalid_freq") })
      } else {
        this.setState({ formError: "" })
      }
    }

    if (
      Object.keys(updatedDiff(this.state.orgProperties, updatedProperties))
        .length > 0
    ) {
      updateBtnDisabled = false
    }

    if (
      properties.mode === "tram" &&
      property.datatype === "dropdown" &&
      property.localeKey === "condition"
    ) {
      updatedProperties["speed"] = this.onPositioningChange(
        updatedProperties.condition
      )
    }

    this.setState({ properties: updatedProperties, updateBtnDisabled })
  }

  onPositioningChange(condition) {
    let value = 25
    if (condition === "underground") {
      value = 30
    }
    return value
  }

  slideInOutDiv() {
    this.setState({ isPanelHidden: !this.state.isPanelHidden })
  }

  toggleEditing(reset: boolean) {
    getAverageSpeed = true
    const displayMode =
      this.state.displayMode == "view_property"
        ? "edit_property"
        : "view_property"
    const properties = reset
      ? JSON.parse(JSON.stringify(this.state.orgProperties))
      : this.state.properties
    this.setState({
      displayMode,
      properties,
      updateBtnDisabled: true,
    })
  }

  toggleAutogenerateStop() {
    this.setState({
      displayMode: "autogenerate_stops",
    })
    this.props.callback("autogenerated_stop", {
      mode: "preview",
      data: { distance: this.getStopDistance() },
    })
  }

  handleDelete() {
    this.props.callback("delete", {})
  }
  handleCombineRoutes() {
    this.props.callback("combine_routes", {
      data: {},
    })
  }

  handleCombineFeatures() {
    this.props.callback("combine_features", {
      data: {},
    })
  }
  render() {
    return this.props.operationMode !== "unknown" ? (
      <div className="container">
        <div
          className={`move-in-div ${
            this.state.isPanelHidden ? "not-visible" : "visible"
          }`}
        >
          <div className="body">{this.getContent()}</div>
          <button className="slideInOutButton" onClick={this.slideInOutDiv}>
            <i
              className={`fa-solid ${
                this.state.isPanelHidden
                  ? "fa-chevron-right"
                  : "fa-chevron-left"
              }`}
            ></i>
          </button>
        </div>
      </div>
    ) : null
  }
}
