import React from "react"
import { CustomControl } from "../specs/Mapbox"
import Tippy from "@tippyjs/react"
import intl from "react-intl-universal"
import { LOCALES_DATA } from "../i18n"
import ReactFlagsSelect from "./languages-selector"
import "./languages-selector/style.scss"
import { Link } from "react-router-dom"
import Modal from "react-responsive-modal"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import ChangePasswordForm from "./user-management/ChangePasswordForm"
import EditDetailsForm from "./user-management/EditDetailsForm"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { showNotification, ToastType } from "../utils/Toaster"

interface NavbarProps {
  updateLocale: () => void
  buttons: CustomControl[]
  user: User
  actionButtons: CustomControl[]
}

interface NavbarState {
  isProfileMenuOpen: boolean
  isMyProfileModalOpen: boolean
  tabIndex: number
  firstName: string
  lastName: string
}

class Navbar extends React.Component<NavbarProps, NavbarState> {
  private profileMenuRef: React.RefObject<HTMLDivElement>

  constructor(props, context) {
    super(props)
    this.state = {
      isProfileMenuOpen: false,
      isMyProfileModalOpen: false,
      tabIndex: 0,
      firstName: "",
      lastName: "",
    }
    this.profileMenuRef = React.createRef<HTMLDivElement>()
    this.onSelectLanguage = this.onSelectLanguage.bind(this)
    this.toggleProfileMenu = this.toggleProfileMenu.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.openMyProfileMenu = this.openMyProfileMenu.bind(this)
    this.closeMyProfilePopup = this.closeMyProfilePopup.bind(this)
    this.signOut = this.signOut.bind(this)
    this.setUpdatedUserDetails = this.setUpdatedUserDetails.bind(this)
  }

  onSelectLanguage(languageCode) {
    localStorage.setItem("lang", languageCode)
    intl
      .init({
        currentLocale: languageCode,
        locales: LOCALES_DATA,
      })
      .then(() => this.props.updateLocale())
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick)
    this.setState({
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName,
    })
  }

  setUpdatedUserDetails(data) {
    this.setState({ firstName: data.firstName, lastName: data.lastName })
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick)
  }

  handleOutsideClick(event: MouseEvent) {
    if (
      this.profileMenuRef.current &&
      !this.profileMenuRef.current.contains(event.target as Node)
    ) {
      this.setState({ isProfileMenuOpen: false })
    }
  }

  toggleProfileMenu() {
    this.setState((prevState) => ({
      isProfileMenuOpen: !prevState.isProfileMenuOpen,
    }))
  }

  signOut() {
    localStorage.removeItem("token")
  }

  closeMyProfilePopup() {
    this.setState({ isMyProfileModalOpen: false, tabIndex: 0 })
  }

  openMyProfileMenu() {
    this.setState({ isMyProfileModalOpen: true, isProfileMenuOpen: false })
  }

  showNotification(message: string, type: ToastType) {
    showNotification(message, type)
  }

  myProfilePopupContent() {
    return (
      <Tabs
        className={"text-[14px]"}
        selectedIndex={this.state.tabIndex}
        onSelect={(index) => this.setState({ tabIndex: index })}
      >
        <TabList className="flex react-tabs__tab-list">
          <Tab className="mr-2 text-white rounded-md px-4 py-1 cursor-pointer">
            {intl.get("edit_details")}
          </Tab>
          <Tab className="text-white rounded-md px-4 py-1 cursor-pointer">
            {intl.get("change_password")}
          </Tab>
        </TabList>
        <TabPanel>
          {
            <EditDetailsForm
              id={this.props.user.id}
              closeMyProfilePopup={this.closeMyProfilePopup}
              setUpdatedUserDetails={this.setUpdatedUserDetails}
              showNotification={this.showNotification}
            ></EditDetailsForm>
          }
        </TabPanel>
        <TabPanel>
          {" "}
          {
            <ChangePasswordForm
              id={this.props.user.id}
              closeMyProfilePopup={this.closeMyProfilePopup}
              showNotification={this.showNotification}
            ></ChangePasswordForm>
          }
        </TabPanel>
      </Tabs>
    )
  }

  render() {
    const { isProfileMenuOpen } = this.state
    return (
      <>
        <nav className="navbar">
          <div className="navbar-logo">
            <span>{intl.get("navbar_title")}</span>
          </div>
          <div className="navbar-buttons">
            {this.props.buttons.map((button) => (
              <Tippy
                key={button.dataTooltip}
                className="tooltip"
                content={button.dataTooltip}
                placement={"bottom"}
                trigger={"mouseenter"}
                interactive={true}
                interactiveBorder={10}
              >
                <button
                  className={`navbarBtn ${button.classes} ${
                    button.conditionalClasses ? button.conditionalClasses() : ""
                  }`}
                  key={button.title}
                  onClick={button.action}
                >
                  <i className={`fa-solid ${button.iconClass}`}></i>
                </button>
              </Tippy>
            ))}
          </div>

          <div className="navbar-profile">
            {this.props.buttons.length
              ? ""
              : this.props.actionButtons.map((button) => (
                  <div key={button.title}>
                    <button
                      className={` ${button.classes}`}
                      key={button.title}
                      onClick={button.action}
                    >
                      <i className={`fa-regular ${button.iconClass}`}></i>
                      <span>{button.label}</span>
                    </button>
                  </div>
                ))}

            <div className="language_selector">
              <i className="fa-solid fa-globe"></i>
              <ReactFlagsSelect
                defaultLanguage={intl.getInitOptions().currentLocale}
                languages={["en", "nl"]}
                className="menu-languages"
                onSelect={this.onSelectLanguage}
                names={"local"}
              />
            </div>
            <div className="relative" ref={this.profileMenuRef}>
              <button
                onClick={this.toggleProfileMenu}
                className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-gray-600 profileBackground"
              >
                <span className="sr-only">Open user menu</span>
                <i className="fa-solid fa-user"></i>
              </button>
              {isProfileMenuOpen && (
                <div className="absolute px-2 py-3 right-0 mt-4 w-48 bg-dark bg-neutral-900 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-10 profileMenu">
                  <div>
                    <span className="block text-base text-center text-gray-900 text-white">
                      {this.state.firstName} {this.state.lastName}
                    </span>
                    <span className="block text-xs text-center text-gray-400 truncate">
                      ({intl.get(`role.${this.props.user.role}`)})
                    </span>
                  </div>
                  <div className="mt-3">
                    <a
                      onClick={this.openMyProfileMenu}
                      className="block py-1 text-sm text-gray-300 cursor-pointer"
                    >
                      <i className="fa-solid fa-user-tie text-xs me-2"></i>
                      {intl.get("label.my_profile")}
                    </a>
                  </div>
                  {this.props.user.role === "admin" ? (
                    <div className="mt-1">
                      <a
                        href="/user/admin"
                        className="block py-1 text-sm text-gray-300"
                      >
                        <i className="fa-solid fa-gear text-xs me-2"></i>
                        {intl.get("label.admin_dashboard")}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="text-center">
                    <Link to="/login">
                      <button
                        onClick={this.signOut}
                        className="mt-4 flex w-auto justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 submitModalButton"
                      >
                        {intl.get("button.sign_out")}
                      </button>
                    </Link>{" "}
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
        <Modal
          open={this.state.isMyProfileModalOpen}
          onClose={this.closeMyProfilePopup}
          center={true}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          styles={{
            modal: {
              background: "#323232",
              color: "#fff",
              width: "50%",
              padding: 0,
              borderRadius: "4px",
            },
            closeButton: { top: 10, right: 5, fill: "white" },
          }}
        >
          <div className="modal-header">{intl.get("label.my_profile")}</div>
          <div className="modal-body">{this.myProfilePopupContent()}</div>
        </Modal>
        <ToastContainer />
      </>
    )
  }
}

export default Navbar
